export default {
  TTYA_SET_CAMPAIGN_AUDIENCE_DATA: "TTYA_SET_CAMPAIGN_AUDIENCE_DATA",
  TTYA_UPDATE_CAMPAIGN_AUDIENCE_DATA: "TTYA_UPDATE_CAMPAIGN_AUDIENCE_DATA",
  TTYA_SET_CAMPAIGN_DATA: "TTYA_SET_CAMPAIGN_DATA",
  TTYA_UPDATE_CAMPAIGN_DATA: "TTYA_UPDATE_CAMPAIGN_DATA",
  TTYA_SET_CAMPAIGN_AUDIENCE: "TTYA_SET_CAMPAIGN_AUDIENCE",
  TTYA_SET_AND_REPLACE_CAMPAIGN_AUDIENCE: "TTYA_SET_AND_REPLACE_CAMPAIGN_AUDIENCE",
  TTYA_UPDATE_CAMPAIGN_AUDIENCE: "TTYA_UPDATE_CAMPAIGN_AUDIENCE",
  TTYA_SET_CAMPAIGN_SORTED_IDS: "TTYA_SET_CAMPAIGN_SORTED_IDS",
  TTYA_UPDATE_CAMPAIGN_SORTED_IDS: "TTYA_UPDATE_CAMPAIGN_SORTED_IDS",
};
