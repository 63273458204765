import React, { lazy } from "react";
import { RouteBasedDrawer } from "@onlinesales-ai/drawer-v2";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { redirectUrl } from "src/store/application";

const DrawerChildren = lazy(() => import("src/pages/routeBasedDrawer/drawerChildren"));

export const drawerProps = {
  POST_FORM: {
    size: "large",
  },
  ACTION_PANEL: {
    size: "medium",
  },
  LEAD_FORM: {
    size: "medium",
  },
  GOAL_LAUNCHER: {
    size: "large",
  },
  MERCHANT_SEGMENT_FORM: {
    size: "large",
  },
  CAMPAIGN_DETAILS_TABLE: {
    size: "large",
  },
  MERCHANT_DETAILS_TABLE: {
    size: "large",
  },
  PROJECT_TRACKER_DETAILS_TABLE: {
    size: "large",
  },
  TTYA_CAMPAIGN_LAUNCHER: {
    size: "large",
  },
  APP_STORE_DETAILS: {
    size: "extra-large",
    containerClass: "app-store-drawer",
  },
  OTT_BILLING_ADVERTISER_MANAGEMENT: {
    size: "extra-large",
  },
  PROMOTION: {
    stopContentClickPropagation: false,
  },
  FINANCE_ADVERTISER_DETAILS: {
    size: "extra-large",
  },
  ADVERTISER_INVOICE_DETAILS: {
    size: "extra-large",
  },
  FINANCE_PAYMENT_TYPE_SUMMARY: {
    size: "extra-large",
  },
  FINANCE_INVOICE_SUMMARY_DETAILS: {
    size: "extra-large",
  },
  FINANCE_INVOICE_RO_SUMMARY_DETAILS: {
    size: "extra-large",
  },
  TTYA_AUDIENCE_CAMPAIGN_LAUNCHER: {
    size: "large",
  },
  TTYA_EVENT_CAMPAIGN_LAUNCHER: {
    size: "large",
  },
  CAMPAIGN_TEMPLATE_FORM: {
    closebutton: false,
    renderInline: true,
    portalElementSelector: "#route-based-drawer-content-pulse",
    size: "fullwidth",
    containerClass: "remove-bg",
  },
  PACKAGE_FORM: {
    closebutton: false,
    renderInline: true,
    portalElementSelector: "#route-based-drawer-content-pulse",
    size: "fullwidth",
    containerClass: "remove-bg",
  },
  BOOKING_FORM: {
    closebutton: false,
    renderInline: true,
    portalElementSelector: "#route-based-drawer-content-pulse",
    size: "fullwidth",
    containerClass: "remove-bg",
  },
  REVIEW_BOOKINGS: {
    closebutton: false,
    renderInline: true,
    portalElementSelector: "#route-based-drawer-content-pulse",
    size: "fullwidth",
    containerClass: "remove-bg",
  },
};

const drawerWithSubComponent = {
  ACTION_PANEL: {
    superAdminUsers: {
      size: "large",
    },
    adminUsers: {
      size: "large",
    },
    opsUsers: {
      size: "large",
    },
    payment: {
      size: "large",
    },
    activityLog: {
      size: "large",
    },
    scheduleReports: {
      size: "large",
    },
    sellerPackageConfiguration: {
      size: "large",
    },
    automatedRulesWallet: {
      size: "large",
    },
    agencyMinMaxBid: {
      size: "extra-large",
    },
  },
};

const RouteBasedDrawerWrapper = ({ redirectUrlTo = () => {} }) => {
  const { component, subComponent } = useParams();
  const location = useLocation();

  return (
    <RouteBasedDrawer
      componentKey={component}
      subComponentKey={subComponent}
      location={location}
      redirectUrl={redirectUrlTo}
      DrawerChildren={DrawerChildren}
      drawerProps={
        drawerWithSubComponent?.[component]?.[subComponent] || drawerProps?.[component] || {}
      }
    />
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirectUrlTo: redirectUrl,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteBasedDrawerWrapper);
