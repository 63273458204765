import _keyBy from "lodash/keyBy";
import _isEmpty from "lodash/isEmpty";
import { subDays, min, max, startOfDay, differenceInDays } from "date-fns/esm";

import {
  format,
  OSWorker,
  getCurrencyCode,
  wait,
  difference,
  parseDateInLocalTimezone,
  RMN_API_WRAPPER_PREFIX,
  fetchBatchWise,
  getAgencySettings,
  formattedDate,
} from "@onlinesales-ai/util-methods-v2";

import { marketingCampaignActionType } from "@onlinesales-ai/constants-v2";
import {
  BrandAdsService,
  ShopsUIService,
  BaseClient,
  NexusService,
} from "@onlinesales-ai/services-v2";

import { updateAudienceData } from "../setting";

// eslint-disable-next-line
import goalsWorker from "worker-plugin/loader?name=brandAdsGoals!@onlinesales-ai/workers-v2/src/brandAdsGoals.worker";
import Types from "./types";

export const setInventoryData = (data, setAsEmpty) => ({
  type: Types.SET_BRAND_ADS_INVENTORY_DATA,
  data,
  setAsEmpty,
});

export const setPageTypes = (pageTypes) => ({
  type: Types.SET_BRAND_ADS_PAGE_TYPES,
  pageTypes,
});

export const setBrandAdsGoals = (goals) => ({
  type: Types.SET_BRAND_ADS_GOALS,
  goals,
});

export const updateBrandAdsGoalData = (id, data) => ({
  type: Types.UPDATE_BRAND_ADS_GOAL_DATA,
  id,
  data,
});

export const setSelectedNetworkListDisplayAds = (goalId, list) => ({
  type: Types.SET_SELECTED_NETWORK_LIST,
  goalId,
  list,
});

export const updateBrandAdsGoalMerchendiseData = (id, datas) => ({
  type: Types.UPDATE_BRAND_ADS_GOAL_MERCHENDISE_DATA,
  id,
  datas,
});

export const updateBrandAdsGoalCampaignData = (id, data) => ({
  type: Types.UPDATE_BRAND_ADS_GOAL_CAMPAIGN_DATA,
  id,
  data,
});

export const setInventorySlotsData = (inventoryId, data) => ({
  type: Types.SET_BRAND_ADS_INVENTORY_SLOTS_DATA,
  data,
  inventoryId,
});

export const setInventorySlotStatus = (data) => ({
  type: Types.SET_BRAND_ADS_INVENTORY_SLOT_STATUS,
  data,
});

export const updateScheduleStatus = (goalId, data, newState) => ({
  type: Types.UPDATE_BRAND_ADS_SCHEDULE_STATUS,
  goalId,
  data,
  newState,
});

export const deleteSlotStatus = (ids) => ({
  type: Types.DELETE_BRAND_ADS_SLOT_STATUS,
  ids,
});

export const updateCampaignStatus = (status, id) => ({
  type: Types.UPDATE_CAMPAIGN_STATUS,
  id,
  status,
});

export const updateCampaignStatusForAdminUi = (campaignId, status) => ({
  // it's only for admin ui
  type: Types.BRAND_ADS_UPDATE_CAMPAIGN_STATUS,
  campaignId,
  status,
});

export const updateCampaignCreativeData = (goalId, data) => ({
  type: Types.UPDATE_BRAND_ADS_GOAL_CREATIVE_DATA,
  goalId,
  adCreativeId: data.adCreativeId,
  data,
});

export const setGoalsInventoryListDataFromAllGoals = (goalsData) => ({
  type: Types.MERGE_ALL_GOALS_DATA_INVENTORY,
  goalsData,
});

export const setInventoryKeywordBiddingGoalData = (goalId, data, keywordType) => ({
  type: Types.SET_BRAND_ADS_KEYWORD_BIDDING_DATA,
  goalId,
  data,
  keywordType,
});

export const setInventoryKeywordBiddingGoalFetchStatus = (goalId, isFetchInProgress) => ({
  type: Types.SET_BRAND_ADS_KEYWORD_BIDDING_FETCH_STATUS,
  goalId,
  isFetchInProgress,
});

export const updateCampaignsForAdminUi = (campaigns) => ({
  type: Types.BRAND_ADS_UPDATE_CAMPAIGNS,
  campaigns,
});

export const updateInventoryKeywordBiddingGoalData = (
  goalId,
  data,
  keywordType,
  keywordCountKey,
) => ({
  type: Types.UPDATE_BRAND_ADS_KEYWORD_BIDDING_DATA,
  goalId,
  data,
  keywordType,
  keywordCountKey,
});

export const setCampaignListForNotification = (campaigns) => ({
  type: Types.AUCTION_GOAL_LIST_NOTIFICATION,
  campaigns,
});

export const fetchAuctionGoalList = ({ data }) => (dispatch, getState) => {
  const goals = {};
  if (Array.isArray(data?.campaigns) && data?.campaigns?.length > 0) {
    data?.campaigns?.forEach((goal) => {
      if (goal && goal?.marketingCampaignId) {
        goals[goal.marketingCampaignId] = {
          isFetchInProgress: false,
          data: {
            id: goal?.marketingCampaignId,
            ...goal,
          },
        };
      }
    });
  }
  dispatch(setCampaignListForNotification(goals));
};

export const fetchInventoryData = (config, clientID) => {
  return async (dispatch, getState) => {
    const state = getState();
    const clientId = clientID || state.Application?.clientId;
    const shopInfo = state.Application?.shopInfo;

    const payload = {
      clientId,
      currency: shopInfo.currencyCode,
      ...config,
    };

    const response = await BrandAdsService.fetchInventories(payload, "BrandAds", {
      servicePrefix: config.rmnVendorId ? RMN_API_WRAPPER_PREFIX : "",
    });

    const adUnits = response.adUnits || [];

    const setAsEmpty = config?.offset === 0 || config?.offset === undefined;

    dispatch(setInventoryData(_keyBy(adUnits, "inventoryAdUnitId"), setAsEmpty));

    return adUnits;
  };
};

export const setGoalsListFetchInProgress = (isLoading) => ({
  type: Types.SET_GOALS_LIST_FETCH_IN_PROGRESS,
  isLoading,
});

export const setGoalsFetchSuccessful = (isSuccessful) => ({
  type: Types.SET_GOALS_FETCH_SUCCESSFUL,
  isSuccessful,
});

export const setGoalsIsFirstCallSuccessful = (isFirstCallSuccessful) => ({
  type: Types.SET_IS_FIRST_CALL_SUCCESSFUL_BRAND_ADS,
  isFirstCallSuccessful,
});

export const setGoalsFirstCallInProgress = (isFirstCallInProgress) => ({
  type: Types.SET_IS_FIRST_CALL_IN_PROGRESS_BRAND_ADS,
  isFirstCallInProgress,
});

export const setCampaignDebuggerData = ({ goalId, traceDetails }) => ({
  type: Types.SET_CAMPAIGN_DEBUGGER_DATA,
  goalId,
  traceDetails,
});

export const updateGoalsOffset = (goalsCount) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { goalsOffset = 0 } = state?.BrandAds || {};
    const offset = goalsCount ? goalsOffset + goalsCount : goalsCount;
    dispatch({
      type: Types.UPDATE_GOALS_OFFSET_BRAND_ADS,
      offset,
    });
  };
};

export const setGoalsSortedIds = (sortedGoalIds) => ({
  type: Types.SET_GOALS_SORTED_IDS,
  sortedGoalIds,
});

export const setGoalsFetchErrorMsg = (errorMsg) => ({
  type: Types.SET_GOALS_FETCH_ERROR_MSG,
  errorMsg,
});

export const makeGoalEmpty = () => ({
  type: Types.MAKE_GOAL_EMPTY,
});

export const makeGoalSortedIdsEmpty = () => ({
  type: Types.MAKE_GOALS_SORTED_EMPTY,
});

export const fetchSlotsData = (config) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId, agencyId } = state.Application;

    const payload = {
      clientId,
      agencyId,
      inventoryAdUnitId: config.inventoryAdUnitId,
      startDate: config.startDate,
      endDate: config.endDate,
      rmnVendorId: config.rmnRetailerId,
    };

    const response = await BrandAdsService.fetchSlots(payload, "BrandAds", {
      servicePrefix: config.rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "",
    });

    dispatch(setInventorySlotsData(config.inventoryAdUnitId, response));

    return response;
  };
};

const goalsApiResolver = (response, limit, rest) => {
  try {
    const { dispatch, isCloningCall = false } = rest || {};
    const goals = {};
    const sortedGoalIds = [];
    if (
      response &&
      response.campaigns &&
      Array.isArray(response.campaigns) &&
      response.campaigns.length
    ) {
      const goalsLength = response.campaigns.length;
      for (let i = 0; i < goalsLength; i++) {
        const goal = response.campaigns[i];
        if (goal && goal.marketingCampaignId) {
          if (goal.campaignSubType === "BLOCK_BUY") {
            goal.inventoryDetails = goal.inventoryDetails?.filter(
              (inventoryInfo) => inventoryInfo?.schedules,
            );
          }
          sortedGoalIds.push(goal?.marketingCampaignId);
          goals[goal.marketingCampaignId] = {
            isFetchInProgress: false,
            data: {
              id: goal.marketingCampaignId,
              ...goal,
            },
          };
        }
      }
    }

    dispatch(setBrandAdsGoals(goals));
    dispatch(updateGoalsOffset(response?.campaigns.length));
    if (!isCloningCall) {
      dispatch(setGoalsSortedIds(sortedGoalIds));
    }
    if ((response?.totalMarketingCampaigns || response?.campaigns.length) < limit) {
      if (!isCloningCall) {
        dispatch(setGoalsListFetchInProgress(false));
        dispatch(setGoalsFetchSuccessful(true));
      }
    }
  } catch (err) {}
  const newArray = [...response.campaigns];
  try {
    newArray.constructor.prototype.customLength =
      response.totalMarketingCampaigns || response?.campaigns.length;
  } catch (err) {}
  return newArray;
};

const statusSortByEnum = ["statusPriority", "creationDate"];

export const fetchBrandAdsGoalsAction = ({
  config = {},
  application = "BrandAds",
  selectedFilters = [],
  extraFilters = [],
  shouldRetry,
  sortByValue,
  isShowLoader = true,
  isCloningCall = false,
}) => {
  return async (dispatch, getState) => {
    const timestamp = new Date().getTime();
    const state = getState();
    const { clientId } = state.Application;
    const { goalsOffset } = state?.BrandAds || {};
    const { isRMNAgency } = state.DomainConfig;

    BrandAdsService.abortByApplication(isCloningCall ? "GoalManager_Campaign_polling" : "GoalManager");

    if (!isCloningCall) {
      dispatch(setGoalsListFetchInProgress(true));
      dispatch(setGoalsFetchSuccessful(false));
    }
    dispatch(setGoalsFirstCallInProgress(isShowLoader));
    if (!shouldRetry) {
      dispatch(makeGoalEmpty());
      dispatch(updateGoalsOffset(0));
      dispatch(makeGoalSortedIdsEmpty());
    }

    const hasStatusFilter = extraFilters.find((filter) => {
      if (filter.column === "status" || filter.column === "effectiveStatus") {
        return true;
      }
    });

    const payload = {
      clientId,
      isFetchTraceSettings: config.isFetchTraceSettings || true,
      isFetchInventorySettings: config.isFetchInventorySettings || true,
      isFetchCreativeSettings: config.isFetchCreativeSettings || true,
      isFetchMerchandiseSettings: config.isFetchMerchandiseSettings || true,
      isFetchAudienceSettings: config.isFetchAudienceSettings || true,
      isFetchNetworkTargetingSettings: config.isFetchNetworkTargetingSettings || true,
      isFetchCustomTargetingSettings: config.isFetchCustomTargetingSettings || true,
      isFetchGeoSettings: config.isFetchGeoSettings || true,
      campaignType: "INVENTORY",
      currency: state.DomainConfig?.marketplaceCurrency,
      selectors: ["id", "name", "creationDate", "statusPriority", "alias"],
      filters: [...extraFilters],
    };

    if (sortByValue) {
      let orderingColumnsValue = [];
      if (sortByValue === "statusPriority") {
        orderingColumnsValue = [...statusSortByEnum];
      }
      payload.orderBy = {
        orderingColumns: orderingColumnsValue,
        sortOrder: "DESC",
      };
    }

    if (!hasStatusFilter) {
      payload.filters.push({
        column: "status",
        operator: "NOT_IN",
        value: ["ARCHIVED"],
      });
    }

    const goals = {};

    try {
      await fetchBatchWise({
        apiCall: isRMNAgency
          ? NexusService.fetchCampaigns.bind(NexusService)
          : BrandAdsService.fetchCampaigns.bind(BrandAdsService),
        payload,
        config: {
          ...(!isRMNAgency && { limit: 20 }),
          offset: shouldRetry && !isCloningCall ? goalsOffset : 0,
        },
        dataResolver: goalsApiResolver,
        dataLengthKey: "customLength",
        application: isCloningCall ? "GoalManager_Campaign_polling" : "GoalManager",
        abortId: `GoalManager_goal_fetch_${timestamp}`,
        useScrollId: false,
        dispatch,
        isCloningCall,
        firstSuccess: () => {
          dispatch(setGoalsFirstCallInProgress(false));
          dispatch(setGoalsIsFirstCallSuccessful(true));
        },
      });
    } catch (err) {
      if (!err?.isAborted) {
        dispatch(setGoalsListFetchInProgress(false));
        dispatch(setGoalsFirstCallInProgress(false));
        dispatch(setGoalsFetchSuccessful(false));
        dispatch(setBrandAdsGoals(goals));
        const currentState = getState();
        if (!Object.keys(currentState.BrandAds?.goalDetails || {}).length) {
          dispatch(setGoalsFetchErrorMsg(err?.errorMsg || BaseClient.getErrorMessage()));
        }
      }
    }
  };
};

export const fetchBrandAdsCampaignInfo = (
  goalId,
  doNotShowLoader = false,
  clientID,
  fetchElementMappings,
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const clientId = clientID || state.Application?.clientId;
    const { isRMNAgency } = state.DomainConfig;

    const payload = {
      clientId,
      isFetchInventorySettings: true,
      isFetchCreativeSettings: true,
      isFetchMerchandiseSettings: true,
      isFetchNetworkTargetingSettings: true,
      isFetchAudienceSettings: true,
      isFetchGeoSettings: true,
      isfetchInventoryRecommendationSettings: false,
      isFetchDeviceSettings: true,
      isFetchCustomTargetingSettings: true,
      marketingCampaignId: goalId,
      campaignType: "INVENTORY",
      currency: state.DomainConfig?.marketplaceCurrency,
      fetchElementMappings,
    };

    let fetchCampaignsFunction = null;

    if (isRMNAgency) {
      fetchCampaignsFunction = NexusService.fetchCampaigns.bind(NexusService);
    } else {
      fetchCampaignsFunction = BrandAdsService.fetchCampaigns.bind(BrandAdsService);
    }

    return new Promise((resolve, reject) => {
      dispatch(
        updateBrandAdsGoalData(goalId, {
          isFetchInProgress: !doNotShowLoader,
        }),
      );
      fetchCampaignsFunction(payload, "BrandAds")
        .then((response) => {
          const data = response?.campaigns?.[0] || {};

          const goalData = {
            id: data.marketingCampaignId,
            ...data,
          };

          if (goalData.campaignSubType === "BLOCK_BUY") {
            goalData.inventoryDetails = goalData.inventoryDetails?.filter(
              (inventoryInfo) => inventoryInfo?.schedules,
            );
          } else {
            if (goalData.hasOwnProperty("bid")) {
              goalData.bid = Number(goalData.bid).toString();
            }

            if (goalData.hasOwnProperty("budget")) {
              goalData.budget = Number(goalData.budget).toString();
            }

            if (goalData.hasOwnProperty("chargableBid")) {
              goalData.chargableBid = Number(goalData.chargableBid).toString();
            }
          }

          if (goalData?.audienceDetails?.length) {
            const audienceDefinitions = _keyBy(goalData?.audienceDetails || [], "settingId");
            dispatch(updateAudienceData(audienceDefinitions));
          }

          dispatch(
            updateBrandAdsGoalData(goalId, {
              data: goalData,
              isFetchInProgress: false,
            }),
          );

          resolve(goalData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const saveBrandAdsInventoryData = (config) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;

    const payload = {
      clientId,
      marketingCampaignId: config.marketingCampaignId,
      inventoryDetails: config.inventoryDetails,
      rmnVendorId: config.rmnRetailerId,
    };

    const response = await BrandAdsService.saveInventory(payload, "BrandAds", {
      servicePrefix: config.rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "",
    });

    if (response?.inventoryDetails?.length) {
      if (config.updateStatus) {
        dispatch(updateScheduleStatus(config.marketingCampaignId, response.inventoryDetails));
      }

      const idToDelete = response.inventoryDetails.map((i) => i.inventoryAdUnitId);
      dispatch(deleteSlotStatus(idToDelete));
    }

    return response;
  };
};

export const saveTargetSettings = (payload) => {
  return async (dispatch, getState) => {
    const response = await BrandAdsService.saveTargetSettings(payload, "BrandAds", {
      servicePrefix: payload.rmnVendorId ? RMN_API_WRAPPER_PREFIX : "",
    });

    if (response?.settings?.length) {
      dispatch(updateBrandAdsGoalMerchendiseData(response.marketingCampaignId, response?.settings));
    }

    return response;
  };
};

export const postBrandAdsPayment = (config) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;
    const { isNewBillingSystem = false } = state?.DomainConfig?.commonConfigs?.financeSupport || {};

    const payload = {
      clientId,
      marketingCampaignId: config.marketingCampaignId,
      scheduleIds: config.scheduleIds,
      amount: config.amount,
      currency: config.currency,
      rmnVendorId: config.rmnRetailerId,
    };

    if (isNewBillingSystem && config?.billingProfileId) {
      payload.billingProfileId = config?.billingProfileId;
    }

    const response = await BrandAdsService.postPayment(payload, "BrandAds", {
      servicePrefix: config.rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "",
    });

    if (config.updateStatus) {
      dispatch(
        updateScheduleStatus(
          config.marketingCampaignId,
          config.paymentInitialtedInvnenotry,
          "BOOKED",
        ),
      );
    }

    return response;
  };
};

const getGoalReportingBatchData = (config) => {
  let filters = [];

  if (config.goalIds && config.goalIds.length) {
    filters.push({
      canonicalColumn: ".campaign_tagging_data.marketing_campaign_id",
      operator: "IN",
      values: config.goalIds.map((goalId) => `${goalId}`),
      type: "text",
      isRhsColumn: false,
    });
  }

  if (config.startDate) {
    filters.push({
      canonicalColumn: "..date",
      operator: ">=",
      values: [config.startDate],
      type: "date",
      isRhsColumn: false,
    });
  }

  if (config.endDate) {
    filters.push({
      canonicalColumn: "..date",
      operator: "<=",
      values: [config.endDate],
      type: "date",
      isRhsColumn: false,
    });
  }

  if (config?.clientId) {
    filters.push({
      canonicalColumn: "..client_id",
      operator: "IN",
      values: [config.clientId],
      type: "numeric",
      isRhsColumn: false,
    });
  }

  if (config.additionalFilters) {
    const additionalFilters = JSON.parse(
      JSON.stringify(config.additionalFilters).replace(/__START_DATE__/g, config.startDate),
    );
    filters = [...additionalFilters, ...filters];
  }

  const payload = {
    start: 0,
    limit: 1000,
    partialData: false,
    selectors: config.selectors || [],
    filters,
    groupByColumns: null,
    orderingColumns: [],
    clientIds: [config.clientId],
    vendors: ["sokrati"],
    clientId: config.clientId,
    agencyId: null,
    userId: null,
    sokratiRequestId: null,
  };

  return {
    hostname: "services.onlinesales.ai",
    endpoint: "/reportingSvc/fetch",
    method: "POST",
    data: payload,
  };
};

const getGoalReportingBatchDataForLiveReporting = (config) => {
  let filters = [
    {
      canonicalColumn: "..agency_id",
      operator: "IN",
      values: [config.agencyId],
      type: "text",
      isRhsColumn: false,
    },
  ];

  if (config.goalIds && config.goalIds.length) {
    filters.push({
      canonicalColumn: ".campaign_tagging_data.marketing_campaign_id",
      operator: "IN",
      values: config.goalIds.map((goalId) => `${goalId}`),
      type: "text",
      isRhsColumn: false,
    });
  }

  if (config.clientId) {
    filters.push({
      canonicalColumn: "..client_id",
      operator: "IN",
      values: [config.clientId],
      type: "text",
      isRhsColumn: false,
    });
  }

  if (config.startDate) {
    filters.push({
      canonicalColumn: "..date_hour",
      operator: ">=",
      values: [config.startDate],
      type: "date",
      isRhsColumn: false,
    });
  }

  if (config.endDate) {
    filters.push({
      canonicalColumn: "..date_hour",
      operator: "<=",
      values: [config.endDate],
      type: "date",
      isRhsColumn: false,
    });
  }

  if (config.additionalFilters) {
    const additionalFilters = JSON.parse(
      JSON.stringify(config.additionalFilters).replace(/__START_DATE__/g, config.startDate),
    );
    filters = [...additionalFilters, ...filters];
  }

  const orderingColumns = [
    {
      name: ".realtime_client_campaign_performance_facts.date_hour",
      order: "ASC",
    },
  ];

  const payload = {
    start: 0,
    limit: 1000,
    partialData: false,
    selectors: config.selectors || [],
    filters,
    groupByColumns: null,
    orderingColumns,
    clientIds: [config.clientId],
    vendors: ["sokrati"],
    clientId: config.clientId,
    agencyId: null,
    userId: null,
    sokratiRequestId: null,
  };

  return {
    hostname: "services.onlinesales.ai",
    endpoint: "/neonService/rtr",
    method: "POST",
    data: payload,
  };
};

export const fetchGoalPerformanceDataWithWorker = ({
  goalKey,
  startDate = subDays(new Date(), 30).valueOf(),
  endDate = new Date().valueOf(),
  selectors = [],
  goalIds,
  goalInfo,
  additionalFilters = [],
  abortId,
  isRealTimeReporting,
  isComparisonEnabled,
  agencyId,
}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application || {};

    const dateFormate = isRealTimeReporting ? "yyyy-MM-dd HH:mm:ss" : "yyyy-MM-dd";

    const fromDate = new Date(startDate);
    const toDate = new Date(endDate);

    const difference = differenceInDays(toDate, fromDate);
    const prevWindowStartDate = format(subDays(fromDate, difference + 1), dateFormate);
    const prevWindowEndDate = format(subDays(toDate, difference + 1), dateFormate);

    selectors.forEach((selectorObj) => {
      const { canonicalColumn } = selectorObj;
      selectorObj.canonicalColumn = canonicalColumn.replace("__CURRENCY__", getCurrencyCode());
    });

    const config = {
      startDate: format(startDate, dateFormate),
      endDate: format(endDate, dateFormate),
      goalIds,
      selectors,
      clientId,
      goalInfo,
      additionalFilters,
      agencyId,
    };

    const request = {
      overallPerformanceData: getGoalReportingBatchData(config),
      dateWisePerformanceData: getGoalReportingBatchData({
        ...config,
        selectors: [
          {
            alias: "date",
            canonicalColumn: "..date",
            isDistinct: false,
            isCount: false,
          },
          ...selectors,
        ],
      }),
    };

    if (isComparisonEnabled) {
      request.overallPerformanceDataPrevWindow = getGoalReportingBatchData({
        ...config,
        startDate: prevWindowStartDate,
        endDate: prevWindowEndDate,
      });
    }

    const realTimeRequest = {
      overallPerformanceData: getGoalReportingBatchDataForLiveReporting(config),
      dateWisePerformanceData: getGoalReportingBatchDataForLiveReporting({
        ...config,
        selectors: [
          {
            alias: "date",
            canonicalColumn: ".realtime_client_campaign_performance_facts.date_hour",
            isDistinct: false,
            isCount: false,
          },
          ...selectors,
        ],
      }),
    };

    const payload = {
      requests: isRealTimeReporting ? realTimeRequest : request,
    };

    try {
      const response = await ShopsUIService.batchCall(payload, "GoalManager", {
        abortId,
      });

      return new Promise((resolve, reject) => {
        const GOAL_WORKER = OSWorker(goalsWorker);

        GOAL_WORKER.onmessage = (event) => {
          const { isErrorOccured, goalData = {}, identifierKey } = event.data || {};

          if (identifierKey === goalKey) {
            if (isErrorOccured) {
              reject({
                errorMsg: "Internal error has occured, Please try again!",
              });
            } else {
              resolve(goalData);
            }

            GOAL_WORKER.terminate();
          }
        };

        GOAL_WORKER.postMessage({
          identifierKey: goalKey,
          config: {
            ...config,
            currencyCode: getCurrencyCode(),
          },
          marketingCampaignActionType,
          isRealTimeReporting,
          isComparisonEnabled,
          response,
        });
      });
    } catch (err) {
      return Promise.reject(err);
    }
  };
};

export const fetchGoalPerformanceDataActionKam = (config) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId, agencyId } = state.Application;
    const {
      startDate: cStartDate,
      endDate: cEndDate,
      goalIds,
      abortId,
      goalKey,
      selectors = [],
      additionalFilters = [],
      isRealTimeReporting,
      isComparisonEnabled,
    } = config || {};

    const metrics = selectors.map((selector) => selector.alias);

    const differenceInDay = differenceInDays(cEndDate, cStartDate);
    const startDate = cStartDate ? formattedDate(cStartDate, "api", { useFormat: true }) : "";
    const endDate = cEndDate ? formattedDate(cEndDate, "api", { useFormat: true }) : "";
    const prevWindowStartDate = cStartDate
      ? formattedDate(subDays(cStartDate, differenceInDay + 1), "api", { useFormat: true })
      : "";
    const prevWindowEndDate = cEndDate
      ? formattedDate(subDays(cEndDate, differenceInDay + 1), "api", { useFormat: true })
      : "";

    const payload = {
      requestType: "REPORTING",
      agencyId,
      clientId,
      metrics,
      reportType: config?.reportType,
      attributes: config?.attributes || [],
      dateRanges: [
        {
          startDate,
          endDate,
          type: "CUSTOM",
        },
      ],
      filters: [
        {
          key: "marketing_campaign_id",
          operator: "IN",
          values: goalIds,
        },
        ...additionalFilters,
      ],
      limit: 100,
      offset: 0,
    };

    const requests = {
      overallPerformanceData: {
        hostname: "services.onlinesales.ai",
        endpoint: "/kamService/report/fetch/client",
        method: "POST",
        data: payload,
      },
      dateWisePerformanceData: {
        hostname: "services.onlinesales.ai",
        endpoint: "/kamService/report/fetch/client",
        method: "POST",
        data: { ...payload, attributes: [...payload.attributes, "date"] },
      },
    };

    if (isComparisonEnabled) {
      requests.overallPerformanceDataPrevWindow = {
        hostname: "services.onlinesales.ai",
        endpoint: "/kamService/report/fetch/client",
        method: "POST",
        data: {
          ...payload,
          dateRanges: [
            {
              startDate: prevWindowStartDate,
              endDate: prevWindowEndDate,
              type: "CUSTOM",
            },
          ],
        },
      };
    }

    try {
      const response = await ShopsUIService.batchCall({ requests }, "GoalManager", {
        abortId,
      });

      const {
        dateWisePerformanceData,
        overallPerformanceData,
        overallPerformanceDataPrevWindow,
      } = response?.data || {};

      return {
        dateWisePerformanceData: dateWisePerformanceData?.data || [],
        overallPerformanceData: overallPerformanceData?.data?.[0] || {},
        overallPerformanceDataPrevWindow: overallPerformanceDataPrevWindow?.data || {},
      };
    } catch (error) {
      Promise.reject(error);
    }
  };
};

export const fetchCreativesForInventory = (request, clientIdToUse) => {
  return (dispatch, getState) => {
    const state = getState();
    const clientId = state.Application.clientId;

    const payload = {
      clientId: clientIdToUse || clientId,
      ...request,
    };

    return new Promise((resolve, reject) => {
      return BrandAdsService.fetchCreativesForInventory(payload, "BrandAds", {
        servicePrefix: payload.rmnVendorId ? RMN_API_WRAPPER_PREFIX : "",
      })
        .then((response) => {
          resolve(response.creatives);
        })
        .catch((err, errMessage) => {
          reject(err);
        });
    });
  };
};

const createSaveCreativeRequest = (config) => {
  const request = {};
  (Object.keys(config) || []).map((key) => {
    request[key] = {
      hostname: "services.onlinesales.ai",
      endpoint: "/brandAdsUIService/creatives/save",
      method: "POST",
      data: config[key],
    };
  });
  return request;
};

export const saveCreativeForInventoryWithStoreUpdate = (request) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      BrandAdsService.postBrandAdsCreatives(request, "BrandAds", {
        servicePrefix: request.rmnVendorId ? RMN_API_WRAPPER_PREFIX : "",
      })
        .then((response) => {
          const data = {
            creativeTemplateId: request.adCreatives?.[0]?.creativeTemplateId,
            templateType: request.adCreatives?.[0]?.templateType,
            inventoryAdUnitIds: request.adCreatives?.[0]?.inventoryAdUnitIds,
            ...(request.adCreatives?.[0]?.creativeDetails?.[0] || {}),
            adCreativeId: response.adCreatives?.[0]?.creativeDetails?.[0]?.adCreativeId,
          };
          dispatch(updateCampaignCreativeData(request.marketingCampaignId, data));
          resolve(response);
        })
        .catch((err) => {
          reject(err?.errorMsg);
        });
    });
  };
};

export const saveCreativeForInventory = (request) => {
  return (dispatch) => {
    return BrandAdsService.postBrandAdsCreatives(request, "BrandAds", {
      servicePrefix: request.rmnVendorId ? RMN_API_WRAPPER_PREFIX : "",
    });
    // return new Promise((resolve, reject) => {
    // .then((response) => {
    //   // const data = {
    //   //   creativeTemplateId: request.creativeTemplateId,
    //   //   templateType: request.templateType,
    //   //   inventoryAdUnitIds: request.inventoryAdUnitIds,
    //   //   ...request.creativeDetails[0],
    //   //   adCreativeId: response.creativeDetails[0].adCreativeId,
    //   // };
    //   // dispatch(updateCreative(data, request.marketingCampaignId));
    //   resolve(response);
    // })
    // .catch((err, errMsg) => {
    //   reject(errMsg);
    // });
    // });
  };
};

export const postCreativesForInventory = (config) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const payload = {
        requests: createSaveCreativeRequest(config),
      };
      ShopsUIService.batchCall(payload, "brandAds")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.errorMsg);
        });
    });
  };
};

export const postBrandAdsCampaignData = (config, { extraKeysToPost = [], rmnRetailerId } = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId, shopInfo, userInfo } = state.Application;
    // const { isNewBillingSystem = false } = state?.DomainConfig?.commonConfigs?.financeSupport || {};

    const newGoalData = {
      currency: shopInfo?.currencyCode,
      ...config,
    };

    const { marketingCampaignId: id } = newGoalData;

    const goalDetails = state?.BrandAds?.goalDetails || {};
    const currentGoalDetail = goalDetails[id] || {};
    const oldData = currentGoalDetail.data || {};

    if (!oldData.name && !newGoalData.name && newGoalData.alias) {
      newGoalData.name = newGoalData.defaultName || newGoalData.alias;
    }

    if (!newGoalData.marketingCampaignId && !newGoalData.country) {
      newGoalData.country = getAgencySettings("goalCountry", state);
    }

    if (!newGoalData.marketingCampaignId && !newGoalData.countryCode) {
      newGoalData.countryCode = getAgencySettings("countryCode", state);
    }

    // if (isNewBillingSystem && !id) {
    //   if (newGoalData.campaignSubType === "AUCTION" && !newGoalData.billingProfileId) {
    //     newGoalData.billingProfileId = `${clientId}`;
    //   }
    // }

    delete newGoalData.defaultName;

    const updatedGoalData = difference(
      oldData,
      {
        name: oldData.name,
        status: oldData.status,
        currency: oldData.currency,
        campaignSubType: oldData?.campaignSubType,
        budgetType: oldData?.budgetType,
        budget: oldData?.budget,
        spendCap: oldData?.spendCap,
        ...newGoalData,
      },
      [
        "name",
        // "status",
        "marketingCampaignId",
        "currency",
        "campaignSubType",
        "budgetType",
        "clientId",
        "spendCap",
        "budget",
        ...extraKeysToPost,
      ],
      {
        getAllArrayValue: false,
        checkArrayWithSorting: true,
      },
    );

    // Monetize EAN Specific handling, BE need this flag for internal handling
    let isEanEnabled = false;
    if (updatedGoalData?.marketingCampaignMetadata?.isEanEnabled) {
      isEanEnabled = true;
    } else if (
      updatedGoalData?.marketingCampaignMetadata?.isEanEnabled !== false &&
      oldData?.marketingCampaignMetadata?.isEanEnabled
    ) {
      isEanEnabled = true;
    }

    const payload = {
      clientId,
      ...updatedGoalData,
      ...(updatedGoalData?.marketingCampaignMetadata && {
        marketingCampaignMetadata: newGoalData.marketingCampaignMetadata,
      }),
      ...(updatedGoalData?.inventoryDetails && { inventoryDetails: newGoalData.inventoryDetails }),
      ...(isEanEnabled && { isEanEnabled }),
      rmnVendorId: rmnRetailerId,
      userId: userInfo?.id,
    };

    let response;

    if (rmnRetailerId) {
      response = await NexusService.postCampaignInfo(payload, "BrandAds");
    } else {
      response = await BrandAdsService.postCampaignInfo(payload);
    }

    const { campaignResponse } = response || {};

    if (campaignResponse) {
      dispatch(
        updateBrandAdsGoalData(campaignResponse.id, {
          data: {
            ...payload,
            ...campaignResponse,
          },
          isFetchInProgress: false,
        }),
      );
    }

    return campaignResponse || {};
  };
};

const getFilterCb = (s) => !["BOOKED", "PAYMENT_INITIATED", "INACTIVE"].includes(s.state);

const pollInventorySchedule = async (dispatch, getState, goaId, clientIdToUse, rmnRetailerId) => {
  const state = getState();
  const { goalDetails = {} } = state.BrandAds;
  const { clientId, agencyId } = state.Application;

  if (!goalDetails || !goalDetails[goaId]?.data) {
    return;
  }

  const { inventoryDetails = [] } = goalDetails[goaId]?.data || {};

  const filteredList = inventoryDetails.filter((inventory) => {
    return inventory.schedules.filter(getFilterCb).length > 0;
  });

  if (!filteredList.length) {
    return;
  }

  const batchRequest = {};

  filteredList.forEach((inventory) => {
    const scheduleDate = inventory.schedules
      .filter(getFilterCb)
      .map((s) => parseDateInLocalTimezone(s.date));

    const maxDate = max(scheduleDate);
    const minDate = min(scheduleDate);

    const request = {
      hostname: "services.onlinesales.ai",
      endpoint: `${
        rmnRetailerId ? RMN_API_WRAPPER_PREFIX : ""
      }/brandAdsUIService/fetchAvailableDatesForInventory`,
      method: "GET",
      data: {
        jsonQuery: {
          agencyId,
          clientId: clientIdToUse || clientId,
          inventoryAdUnitId: inventory.inventoryAdUnitId,
          startDate: format(minDate),
          endDate: format(maxDate),
          rmnVendorId: rmnRetailerId,
        },
      },
    };

    batchRequest[inventory.inventoryAdUnitId] = request;
  });

  const response = await ShopsUIService.batchCall({
    requests: batchRequest,
  });

  const { data = {} } = response || {};
  const status = {};

  Object.keys(data).forEach((inventoryAdUnitId) => {
    const slotsData = data[inventoryAdUnitId];

    status[inventoryAdUnitId] = {};

    let { schedules = [] } = inventoryDetails.find(
      (i) => `${i.inventoryAdUnitId}` === `${inventoryAdUnitId}`,
    );

    schedules = schedules.filter(getFilterCb);

    status[inventoryAdUnitId].schedules = schedules.reduce((memo, s) => {
      let slotInfo = {
        availableImpressions: 0,
        status: "AVAILABLE",
      };

      if (!["BOOKED", "PAYMENT_INITIATED", "INACTIVE"].includes(s.state)) {
        if (slotsData.slots) {
          slotInfo = slotsData.slots.find(
            (slot) =>
              startOfDay(new Date(slot.date)).valueOf() === startOfDay(new Date(s.date)).valueOf(),
          );
        }

        if (!slotInfo) {
          slotInfo = slotsData.defaultSlotConfiguration;
        }
      }

      if ((slotInfo.availableImpressions || 0) < s.blockedImpressions) {
        slotInfo.status = "NOT_AVAILABLE";
      } else {
        slotInfo.status = "AVAILABLE";
      }

      memo[s.scheduleId] = {
        ...s,
        bookStatus: slotInfo.status,
      };

      return memo;
    }, {});

    const totalSchedule = Object.keys(status[inventoryAdUnitId].schedules).length;
    const availableScheduleLength = Object.values(status[inventoryAdUnitId].schedules).filter(
      (s) => s.bookStatus === "AVAILABLE",
    ).length;

    let finalStatus = "PARTIAL_AVAILABLE";

    if (totalSchedule === availableScheduleLength) {
      finalStatus = "AVAILABLE";
    }

    if (availableScheduleLength === 0) {
      finalStatus = "NOT_AVAILABLE";
    }

    status[inventoryAdUnitId].bookStatus = finalStatus;
  });

  dispatch(setInventorySlotStatus(status));
};

export const fetchInventoryAvailabilityStauts = (clientIdToUse, rmnRetailerId) => {
  let poll = true;

  return (dispatch, getState) => {
    return {
      startPoll: async (goaId) => {
        dispatch(setInventorySlotStatus({}));
        while (poll) {
          if (poll) {
            try {
              if (navigator?.onLine && document?.visibilityState === "visible") {
                await pollInventorySchedule(dispatch, getState, goaId, clientIdToUse, rmnRetailerId);
              }
            } catch (err) {
              if (err?.errorCode === "UNAUTHORIZED" || err?.errorCode === "AD0000") {
                poll = false;
              }
            }
          }
          await wait(10000);
        }
      },
      stopPoll: () => {
        dispatch(setInventorySlotStatus({}));
        poll = false;
      },
    };
  };
};

export const fetchCampaignComments =
  ({ entityId, clientId, entityType, marketplaceClientId, passMarketId, rmnRetailerId }, request) =>
  (dispatch) => {
    const payload = {
      clientId: passMarketId ? marketplaceClientId : clientId,
      entityId: `${entityId}`,
      entityType: entityType || "CAMPAIGN",
      limit: 1000,
      offset: 0,
      rmnVendorId: rmnRetailerId,
      ...request,
    };

    return new Promise((resolve, reject) => {
      BrandAdsService.fetchCampaignComments(payload, "BrandAds", {
        servicePrefix: rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "",
      })
        .then((response) => {
          let { comments = [] } = response;
          comments = comments.reverse();
          // dispatch(setCampaignComments(goalId, comments));
          resolve(comments);
        })
        .catch((error) => {
          reject(error?.errorMsg);
        });
    });
  };

export const postCampaignComment =
  ({ entityId, clientId, entityType, marketplaceClientId, passMarketId, rmnRetailerId }, comment) =>
  (dispatch) => {
    const payload = {
      clientId: passMarketId ? marketplaceClientId : clientId,
      entityId: `${entityId}`,
      entityType: entityType || "CAMPAIGN",
      comments: [comment],
      rmnVendorId: rmnRetailerId,
    };

    return new Promise((resolve, reject) => {
      BrandAdsService.postCampaignComment(payload, "BrandAds", {
        servicePrefix: rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "",
      })
        .then((response) => {
          let { comments = [] } = response;
          let commentObj = {};
          if (comments.length) {
            commentObj = comments[0];
            // dispatch(addCampaignComment(goalId, commentObj));
          }
          resolve(commentObj);
        })
        .catch((error) => {
          reject(error?.errorMsg);
        });
    });
  };

export const postCampaignStatusUpdate = (request, config = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const clientId = state.Application.clientId;

    const goalDetails = state?.BrandAds?.goalDetails || {};
    const currentGoalDetail = goalDetails[request?.marketingCampaignId] || {};
    const oldData = currentGoalDetail.data || {};

    // Monetize EAN Specific handling, BE need this flag for internal handling
    let isEanEnabled = false;
    if (request?.marketingCampaignMetadata?.isEanEnabled) {
      isEanEnabled = true;
    } else if (
      request?.marketingCampaignMetadata?.isEanEnabled !== false &&
      oldData?.marketingCampaignMetadata?.isEanEnabled
    ) {
      isEanEnabled = true;
    }

    const payload = {
      clientId,
      ...request,
      ...(isEanEnabled && { isEanEnabled }),
    };

    return new Promise((resolve, reject) => {
      BrandAdsService.postCampaignInfo(payload)
        .then((response) => {
          const { campaignResponse = {} } = response;
          dispatch(updateCampaignStatus(campaignResponse.status, campaignResponse.id));
          resolve(campaignResponse);
        })
        .catch(({ err, errorMsg }) => {
          reject(err?.errorMsg);
        });
    });
  };
};

export const fetchBrandAdsPageTypeInfo =
  ({ clientID, rmnRetailerId, isSetToStore = true, ...restconfig }) =>
  async (dispatch, getState) => {
    const state = getState();
    const clientId = clientID || state.Application?.clientId;

    const payload = {
      clientId,
      rmnVendorId: rmnRetailerId,
      ...restconfig,
    };

    const response = await BrandAdsService.fetchPageTypes(payload, "BrandAds", {
      servicePrefix: rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "",
    });

    const pageTypes = response.pageTypes || [];
    pageTypes.forEach((pageTypeInfo) => {
      if (!pageTypeInfo.label) {
        pageTypeInfo.label = pageTypeInfo.value;
      }
    });
    const processedPageTypes = _keyBy(pageTypes, "value");

    if (isSetToStore) {
      dispatch(setPageTypes(processedPageTypes));
    }

    return pageTypes;
  };
