import React from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import { withTranslation } from "react-i18next";

import { Input } from "@onlinesales-ai/input-v2";
import { validateValue } from "@onlinesales-ai/util-methods-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";

import FormWrapper from "../../FormWrapper";

import "./index.less";

class TagInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
    };

    this.validate(this.getValue());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newValue = _get(nextProps.formValues, nextProps.dataKey);

    if (!_isEqual(newValue, this.getValue())) {
      this.validate(newValue);
    }
  }

  getValue = () => {
    const { dataKey, formValues } = this.props;
    return _get(formValues, dataKey);
  };

  getInputValue = () => {
    return this.state.inputValue;
  };

  validate = (value) => {
    const { onError, dataKey, validations = [], title } = this.props;
    let errorMsg = null;

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "nonEmpty":
          {
            if (_isEmpty(value)) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  onChangeInput = (value) => {
    this.setState({
      inputValue: value,
    });
  };

  handleValidationAndUpdate = () => {
    const { inputValue } = this.state;
    const { inputValidations, formValues, title, t, showToastMessage } = this.props;
    const trimmedInputValue = inputValue?.trim();

    if (trimmedInputValue) {
      if (inputValidations?.length) {
        const validationValue = validateValue({
          validations: inputValidations,
          value: trimmedInputValue,
          formValues,
          title,
        });

        if (!validationValue.isValid) {
          showToastMessage({
            type: "ERROR",
            messageToDisplay: t(validationValue.errorMsg),
            toastDuration: 8000,
          });
          return;
        }
      }

      this.onValueUpdate(trimmedInputValue);
      this.setState({
        inputValue: "",
      });
    }
  };

  onKeyPress = (event) => {
    if (event.which === 13) {
      this.handleValidationAndUpdate();
    }
  };

  onBlur = () => {
    this.handleValidationAndUpdate();
  };

  onValueUpdate = (value) => {
    const { onChange, dataKey } = this.props;

    const values = this.getValue() || [];

    if (!values.find((v) => v === value)) {
      onChange({
        [dataKey]: [...values, value],
      });
    }
  };

  onRemove = (valueToRemove) => {
    const { onChange, dataKey } = this.props;

    const value = this.getValue() || [];

    onChange({
      [dataKey]: value.filter((v) => v !== valueToRemove),
    });
  };

  render() {
    const {
      // FormWrapper props
      labelColumns,
      formGroupClassName,
      formGroupInnerClassName,
      guidText,
      guidElement,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,
      bottomGuideText,
      contentInfoTooltip,
      contentClassName,

      // tag input props
      t,
      dataKey,
      isEditable,
      showErrors,
      formErrors,
      placeholder,
      disabled,
      tagWrapperClassName,
      labelDefinationKey,
      infoTooltip,
      defaultPlaceholder,
      isRequired,
      labelClassName,
    } = this.props;

    const { inputValue } = this.state;
    const value = this.getValue() || [];

    return (
      <FormWrapper
        labelColumns={labelColumns}
        formGroupClassName={`label-align-start-with-input ${formGroupClassName}`}
        formGroupInnerClassName={formGroupInnerClassName}
        hasError={showErrors && formErrors[dataKey]}
        error={formErrors[dataKey]}
        apiError={apiError}
        warning={warning}
        title={title}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        isEditable={isEditable}
        infoTooltip={infoTooltip}
        labelDefinationKey={labelDefinationKey}
        contentInfoTooltip={contentInfoTooltip}
        isRequired={isRequired}
        labelClassName={labelClassName}
        contentClassName={contentClassName}
      >
        <div className="os-input-control-wrapper-updated">
          {isEditable && (
            <Input
              type="text"
              className="form-control simple-input"
              value={inputValue}
              onChange={this.onChangeInput}
              onKeyPress={this.onKeyPress}
              placeholder={t(placeholder)}
              disabled={disabled}
              inputWrapperClassName={tagWrapperClassName}
              suffix={
                <span className="icon icon-enter" onClick={this.handleValidationAndUpdate} />
              }
              onBlur={this.onBlur}
            />
          )}
        </div>
        {isEditable && (guidText || guidElement) && (
          <div className="guide-text">{guidElement || t(guidText)}</div>
        )}
        <div className="tab-input-field-form">
          {!_isEmpty(value) ? (
            <>
              {value.map((v) => (
                <span key={v} className="pill">
                  <span>{v}</span>
                  {isEditable && (
                    <i onClick={() => this.onRemove(v)} className="remove-pill icon icon-close1" />
                  )}
                </span>
              ))}
            </>
          ) : (
            !isEditable && defaultPlaceholder
          )}
        </div>
        {
          bottomGuideText &&
          <div className="guide-text">{t(bottomGuideText)}</div>
        }
      </FormWrapper>
    );
  }
}

TagInput.defaultProps = {
  dataKey: null,
  title: "",
  labelColumns: 3,
  validations: [
    {
      type: "nonEmpty",
      msg: "Please enter __FIELD_TITLE__",
    },
  ],
  defaultPlaceholder: "NA",
};

export default withTranslation(null, { withRef: true })(OSHOCWithUtilities(TagInput));
