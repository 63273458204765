/* eslint-disable no-nested-ternary */
import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import _isEmpty from "lodash/isEmpty";

import classnames from "classnames";

import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { Button } from "@onlinesales-ai/button-v2";
import VideoPlayer from "@onlinesales-ai/video-player-v2";
import { FormWrapper } from "@onlinesales-ai/form-components-v2";
import { FilePreviewWithDrawer } from "@onlinesales-ai/utils-components-v2";
import { Steps, Step } from "@onlinesales-ai/steps-v2";
import { markDownToReact } from "@onlinesales-ai/html-v2";

import MediaUploadWithProgress from "../mediaUpload/MediaUploadWithProgress";
import MediaLibraryDrawer from "../mediaLibrary/withDrawer";

import "./index.less";

const defaultMediaProps = {
  mediaToShow: ["CREATIVES"],
  mediaType: "IMAGE",
  mediaConfig: {
    CREATIVES: {
      allowedFileTypes: "image/*",
    },
  },
};

class MediaLibraryForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      filePreviewDrawerConfig: {
        isOpen: false,
      },
    };

    this.validate(this.getValue());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { mediaType } = this.props;
    const newValue = _get(nextProps.formValues, nextProps.dataKey);
    // when media type is changed from photo to video newValue stays undefined
    // hence check for mediaType
    if (newValue !== this.getValue() || nextProps.mediaType !== mediaType) {
      this.validate(newValue);
    }
  }

  getValue = () => {
    const { dataKey, formValues } = this.props;
    return _get(formValues, dataKey);
  };

  validate = (value) => {
    const { onError, dataKey, validations = [], title } = this.props;
    let errorMsg = null;

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "nonEmpty":
          {
            if (!value) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  onMediaSelect = ({ value, fileInfo, fileType, shouldToggleDrawer = true }) => {
    const { onChange, dataKey, extraDataKeys, pickDataFromKey } = this.props;
    let valueToSet = value?.[0]?.url;

    if (pickDataFromKey && valueToSet) {
      if (!_isEmpty(fileInfo)) {
        valueToSet = _get(fileInfo, pickDataFromKey);
      } else {
        // in case select from library we get url of image
        valueToSet = valueToSet.split("/")?.pop()?.split(".")?.[0] || "";
      }
    }

    const dataToChange = {
      [dataKey]: valueToSet,
    };

    if (extraDataKeys) {
      extraDataKeys.forEach(({ dataKey: eDataKey, creativeDataKey }) => {
        dataToChange[eDataKey] = _get({ ...fileInfo, fileType }, creativeDataKey || eDataKey);
      });
    }

    onChange(dataToChange);

    if (shouldToggleDrawer) {
      this.onToggleDrawer();
    }
  };

  onClickRemove = (event) => {
    event.stopPropagation();
    const { onChange, dataKey, isEditable } = this.props;
    if (isEditable) {
      onChange({
        [dataKey]: undefined,
      });
    }
  };

  onToggleDrawer = () => {
    const { disabled, isEditable, showMediaLibrary } = this.props;

    if (showMediaLibrary) {
      if (!disabled && isEditable) {
        this.setState({
          isOpen: !this.state.isOpen,
        });
      }
    }
  };

  onUploadError = (errorMsg) => {
    this.props.showToastMessage({
      type: "ERROR",
      messageToDisplay: errorMsg,
      actionButtonLabel: null,
      toastDuration: 5000,
    });
  };

  renderPreview = (value, selectedImageToShow) => {
    const {
      title,
      mediaType,
      formValues,
      fileNameDataKey,
      renderDownloadFile,
      fileTypeDataKey,
      renderOverlayDomNode,
      defaultFileType,
      showDownloadFile,
      showPreviewButton = true,
      previewSize,
      isPublicUrl,
    } = this.props;

    if (mediaType === "PHOTO") {
      if (typeof renderOverlayDomNode === "function") {
        return renderOverlayDomNode(selectedImageToShow || value, this.props);
      } else {
        return <AsyncImage imgSrc={selectedImageToShow || value} />;
      }
    } else if (mediaType === "FILE" && !_isEmpty(value)) {
      const fileName = _get(formValues, fileNameDataKey);
      const fileType = _get(formValues, fileTypeDataKey) || defaultFileType;
      let previewIcon = "";

      if (fileType?.includes("xlsx")) {
        previewIcon = "icon-excel-2 green-icon";
      } else if (fileType?.startsWith("application")) {
        previewIcon = "icon-file-pdf-o";
      } else if (fileType?.startsWith("image")) {
        previewIcon = "icon-file-image-o";
      } else {
        previewIcon = "icon-file-2 generic-file-icon";
      }

      return (
        <div className="file-container">
          <div className={`pdf-preview d-center cursor-default ${previewSize}`}>
            <span className={`icon ${previewIcon}`} />
            <div className="filename-text ellipsis-line twoline">{fileName}</div>
            {showDownloadFile ? (
              <Button link onClick={() => window.open(value, "_blank")}>
                Download File
              </Button>
            ) : null}
            {typeof renderDownloadFile === "function" && renderDownloadFile(value, fileName)}
          </div>
          {showPreviewButton ? (
            <div
              className="overlay-icon"
              onClick={() =>
                this.setState({
                  filePreviewDrawerConfig: {
                    isOpen: true,
                    fileUrl: value,
                    headerTitle: title,
                    fileType,
                    isPublicUrl,
                  },
                })
              }
            >
              <span className="icon icon-preview" />
            </div>
          ) : null}
        </div>
      );
    } else {
      return <VideoPlayer src={selectedImageToShow || value} />;
    }
  };

  renderImage = (value) => {
    const {
      isEditable,
      removeAble,
      selectedImageToShow,
      userInfo,
      creativeTag,
      uploadTags,
      uploadFile,
      directUploadFileProps,
      clientId,
      shouldRenderDirectUpload = false,
    } = this.props;

    const { filePreviewDrawerConfig } = this.state;
    return shouldRenderDirectUpload ? (
      <MediaUploadWithProgress
        sampleFileText=""
        isUploadOnCloudinary
        onUpload={(url, fileInfo, fileType) =>
          this.onMediaSelect({ value: [{ url }], shouldToggleDrawer: false, fileInfo, fileType })
        }
        overlayDomNode={<AsyncImage imgSrc={selectedImageToShow || value} />}
        disabled={!isEditable}
        showSuccessInModal={false}
        clientId={clientId}
        creativeTag={creativeTag}
        uploadTags={uploadTags}
        userInfo={userInfo}
        isValidateSize
        isValidateDimension
        shouldShowMediaUploadOnUpload
        {...uploadFile}
        {...directUploadFileProps}
      >
        {({ onClick }) => {
          return (
            <Button type="default" icon="icon-upload1" onClick={onClick}>
              Upload
            </Button>
          );
        }}
      </MediaUploadWithProgress>
    ) : (
      <div className="creative-image" onClick={this.onToggleDrawer}>
        {this.renderPreview(value, selectedImageToShow)}
        <FilePreviewWithDrawer
          {...filePreviewDrawerConfig}
          onExited={() => this.setState({ filePreviewDrawerConfig: { isOpen: false } })}
          onClickClose={() =>
            this.setState((oldState) => ({
              filePreviewDrawerConfig: {
                ...(oldState?.filePreviewDrawerConfig || {}),
                isOpen: false,
              },
            }))
          }
        />
        {isEditable && removeAble ? (
          <div className="delete-icon icon icon-trash-o" onClick={this.onClickRemove} />
        ) : null}
      </div>
    );
  };

  renderChooseCreative = () => {
    const {
      isEditable,
      userInfo,
      creativeTag,
      uploadTags,
      uploadFile,
      directUploadFileProps,
      clientId,
      shouldShowDirectUploadBtn = false,
      showMediaLibrary = true,
    } = this.props;

    return (
      <>
        {shouldShowDirectUploadBtn && (
          <MediaUploadWithProgress
            sampleFileText=""
            isUploadOnCloudinary
            onUpload={(url, fileInfo, fileType) =>
              this.onMediaSelect({
                value: [{ url }],
                shouldToggleDrawer: false,
                fileInfo,
                fileType,
              })
            }
            overlayDomText="Upload Image file"
            disabled={!isEditable}
            showSuccessInModal={false}
            clientId={clientId}
            creativeTag={creativeTag}
            uploadTags={uploadTags}
            userInfo={userInfo}
            isValidateSize
            isValidateDimension
            {...uploadFile}
            {...directUploadFileProps}
          >
            {({ onClick }) => {
              return (
                <Button type="default" icon="icon-upload1" onClick={onClick}>
                  Upload
                </Button>
              );
            }}
          </MediaUploadWithProgress>
        )}
        {showMediaLibrary && (
          <div
            className={`creative-form-btn ${isEditable ? "" : "disabled"} ${
              shouldShowDirectUploadBtn ? "show-upload-block" : ""
            }`}
            onClick={this.onToggleDrawer}
          >
            <Trans>
              <span className="highlight">Choose</span> creative from library
            </Trans>
          </div>
        )}
      </>
    );
  };

  renderCreativeBtnDom = () => {
    const value = this.getValue();
    const { disabled, isEditable, showErrors, formErrors, dataKey, showTextError, fallbackValue, creativeImageClass } =
      this.props;

    const hasError = !showTextError && showErrors && formErrors[dataKey];
    return (
      <div
        className={classnames("creative-action-block", creativeImageClass, {
          "is-creative-image": value,
          "disabled": disabled,
          "non-editable": !isEditable && !value,
          "has-error": hasError,
        })}
      >
        {value ? (
          this.renderImage(value)
        ) : (
          <>{!isEditable && fallbackValue ? fallbackValue : this.renderChooseCreative()}</>
        )}
      </div>
    );
  };

  onClickDownload = () => {
    const { sampleFile } = this.props;

    window.open(sampleFile, "_blank");
  };

  renderDom() {
    const {
      isEditable,
      nonEditableTooltipPopoverMsg,
      downloadLinkText,
      showUploadButton,
      howItWorksList,
    } = this.props;
    const value = this.getValue();

    return (
      <>
        {isEditable ? (
          <>
            {showUploadButton ? (
              <div>
                <Steps showStepNumber>
                  <Step>
                    {() => {
                      return (
                        <Button
                          className="download-link btnheight"
                          type="primary"
                          link
                          icon="icon-download1"
                          iconWeight
                          onClick={this.onClickDownload}
                        >
                          {downloadLinkText}
                        </Button>
                      );
                    }}
                  </Step>
                  <Step>{this.renderCreativeBtnDom}</Step>
                </Steps>
                {howItWorksList?.length && (
                  <div className="how-it-works">
                    <h4>How it works?</h4>
                    <ul>
                      {howItWorksList.map((item) => <li>{markDownToReact(item)}</li>)}
                    </ul>
                  </div>
                )}
              </div>
            ) : (
              this.renderCreativeBtnDom()
            )}
          </>
        ) : (
          <WithTooltip placement="top" title={!value ? nonEditableTooltipPopoverMsg : ""}>
            {this.renderCreativeBtnDom()}
          </WithTooltip>
        )}
      </>
    );
  }

  render() {
    const {
      // FormWrapper props
      labelColumns,
      formGroupClassName,
      formGroupInnerClassName,
      guidText,
      guidElement,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,
      labelDefinationKey,

      // media library props
      dataKey,
      isEditable,
      showErrors,
      formErrors,
      isMobile,
      clientId,
      userInfo,
      creativeTag,
      mediaLibraryProps,
      uploadFile,
      uploadTags,
      showTextError,
      isRequired,
      labelClassName,
    } = this.props;

    const { isOpen } = this.state;

    return (
      <FormWrapper
        labelColumns={labelColumns}
        formGroupClassName={`media-upload-form-wrapper ${formGroupClassName}`}
        formGroupInnerClassName={formGroupInnerClassName}
        hasError={showErrors && formErrors[dataKey]}
        error={showTextError ? formErrors[dataKey] : null}
        apiError={apiError}
        guidElement={guidElement}
        guidText={guidText}
        warning={warning}
        title={title}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        isEditable={isEditable}
        isRequired={isRequired}
        labelClassName={labelClassName}
        labelDefinationKey={labelDefinationKey}
      >
        <MediaLibraryDrawer
          isOpen={isOpen}
          onMediaSelect={(selectedValue) => this.onMediaSelect({ value: selectedValue })}
          onClose={this.onToggleDrawer}
          isMobile={isMobile}
          clientId={clientId}
          userInfo={userInfo}
          creativeTag={creativeTag}
          uploadFile={uploadFile}
          uploadTags={uploadTags}
          {...{ ...defaultMediaProps, ...mediaLibraryProps }}
        />
        {this.renderDom()}
      </FormWrapper>
    );
  }
}

MediaLibraryForm.defaultProps = {
  dataKey: null,
  title: "",
  labelColumns: 3,
  mediaLibraryProps: {},
  validations: [
    {
      type: "nonEmpty",
      msg: "Please upload creative",
    },
  ],
  nonEditableTooltipPopoverMsg: "Media is not editable",
  removeAble: true,
  showTextError: true,
  showMediaLibrary: true,
  isPublicUrl: false,
  mediaType: "PHOTO",
  downloadLinkText: "Download Sample File",
  previewSize: "",
};

const mapStateToProps = (state) => {
  const { clientId, userInfo } = state.Application || {};

  return {
    clientId,
    userInfo,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withTranslation()(OSHOCWithUtilities(MediaLibraryForm)));
