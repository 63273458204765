import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import { Carousel } from "react-bootstrap";

import AsyncImage from "@onlinesales-ai/async-image-v2";
import { ModalWindow } from "@onlinesales-ai/modal-window-v2";
import { Button } from "@onlinesales-ai/button-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import VideoPlayer from "@onlinesales-ai/video-player-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { brandAdsCreativeStatusEnum } from "@onlinesales-ai/constants-v2";

import "./index.less";

const MediaPreview = ({
  imageType,
  value,
  isEditable,
  isReviewMode,
  onToggleDrawer,
  onClickRemove,
  deviceConfig,
  onChooseCreative,
  onClickComments,
  creativeConfig,
  showEditIfNotEditable,
  onToggleActivePause,
  customPreviewRender,
  previewDatas,
  showToastMessage,
  enableImageDownload,
  simplePreview,
  previewIcon,
}) => {
  const [modalStatus, setModalStatus] = useState({
    isShow: false,
    images: [],
    videos: [],
    imagesWithText: [],
  });
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const hasMutipleContent = (content) => {
    return (
      Object.values(content)?.reduce((memo, data) => {
        return memo + data?.length;
      }, 0) > 1
    );
  };

  const downloadImage = async (val) => {
    setIsDownloadInProgress(true);
    try {
      let urlSplitList = val.includes("/") ? val?.split("/") : val;
      urlSplitList = urlSplitList?.[urlSplitList.length - 1].includes("?") ? urlSplitList?.[urlSplitList.length - 1].split("?")?.[0] : urlSplitList?.[urlSplitList.length - 1];
      const fileName = urlSplitList;
      if (val.includes("osads.gumlet.io")) {
        val += "?original=true";
      }
      const response = await fetch(val);
      const blobImage = await response.blob();
      const href = URL.createObjectURL(blobImage);
      const anchorElement = document.createElement("a");
      anchorElement.href = href;
      anchorElement.download = fileName;
      anchorElement.click();
      setIsDownloadInProgress(false);
    } catch (err) {
      setIsDownloadInProgress(false);
      showToastMessage({
        messageToDisplay: "Unable to download the media",
        type: "ERROR",
        toastDuration: 5000,
      });
    }
  };

  const renderDownloadBtn = (val) => {
    if (enableImageDownload) {
      return (
        <div className="media-download-image-btn d-align-end">
          <WithTooltip title="Download" placement="top">
            <Button
              type="default"
              iconWeight
              icon="icon-download1"
              onClick={() => downloadImage(val)}
              isLoading={isDownloadInProgress}
            />
          </WithTooltip>
        </div>
      );
    }
    return null;
  };

  const renderPreviewContent = (val, key) => {
    if (key === "VIDEO") {
      return (
        <VideoPlayer
          enableWrapperClick
          allowPlayOnHover
          shouldShowControls={false}
          src={val}
          showPreview={false}
          enableDownload={false}
        />
      );
    } else if (key === "IMAGE") {
      return <AsyncImage imgSrc={val} alt="Creative" />;
    } else if (key === "PRODUCT_LIST") {
      return (
        <div className="media-preview-preview-modal-container">
          <AsyncImage imgSrc={val?.image} alt="Product Image" />
          <WithTooltip title={val?.text}>
            <div className="product-image-caption ellipsis-text">{val?.text}</div>
          </WithTooltip>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderModal = () => {
    return (
      <ModalWindow
        containerClass="media-uploader-preview-modal"
        headerTitle={modalStatus.isPlacementPreview ? "Ad Placement Preview" : "Preview"}
        isShow={modalStatus.isShow}
        onModalDestroy={() => {
          setModalStatus({
            images: [],
            videos: [],
            isShow: false,
          });
        }}
      >
        <ModalWindow.Body>
          {hasMutipleContent({
            images: modalStatus.images,
            videos: modalStatus.videos,
            imagesWithText: modalStatus.imagesWithText,
          }) ? (
            <div className="media-creative-image-carousel">
              <Carousel
                indicators
                interval={null}
                wrap={false}
                prevIcon={<span className="icon icon-angle-left" />}
                nextIcon={<span className="icon icon-angle-right" />}
              >
                {(modalStatus.images || []).map((image = {}) => {
                  return (
                    <Carousel.Item>
                      {renderDownloadBtn(image)}
                      {renderPreviewContent(image, "IMAGE")}
                    </Carousel.Item>
                  );
                })}
                {(modalStatus.videos || []).map((video = {}) => {
                  return <Carousel.Item>{renderPreviewContent(video, "VIDEO")}</Carousel.Item>;
                })}
                {(modalStatus.imagesWithText || []).map((val = {}) => {
                  return (
                    <Carousel.Item>
                      {renderPreviewContent(val, "PRODUCT_LIST")}
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
            ) : (
              <div className="brandAds-creative-priview-img">
                {!!modalStatus.images?.length && (
                  <>
                    {renderDownloadBtn(modalStatus.images?.[0])}
                    {renderPreviewContent(modalStatus.images?.[0], "IMAGE")}
                  </>
                )}
                {!!modalStatus.videos?.length &&
                  renderPreviewContent(modalStatus.videos?.[0], "VIDEO")}
                {!!modalStatus.imagesWithText?.length && (
                  <>
                    {renderPreviewContent(modalStatus.imagesWithText?.[0], "PRODUCT_LIST")}
                  </>
                )}
                {!modalStatus.images?.length &&
                  !modalStatus.videos?.length &&
                  !modalStatus.imagesWithText?.length && <AsyncImage />}
              </div>
            )}
        </ModalWindow.Body>
      </ModalWindow>
    );
  };
  return (
    <div
      className={`media-creative-image ${creativeConfig.containerClass} ${isReviewMode && "admin-view"} ${simplePreview ? "simple-preview" : ""}`}
    >
      {((isEditable || creativeConfig.status === "UNDER_REVIEW") && simplePreview) && (
        <div className="simple-preview-close d-center" onClick={onClickRemove}>
          <span className="icon icon-close1" />
        </div>
      )}
      {renderModal()}
      {simplePreview ? (
        <WithTooltip title="Preview">
          <div
            className="preview simple-preview-expand d-center"
            onClick={() => {
              if (previewDatas?.length > 0) {
                setModalStatus({
                  images: previewDatas,
                  isPlacementPreview: true,
                  isShow: true,
                });
              } else {
                setModalStatus({
                  ...value,
                  isShow: true,
                });
              }
            }}
          >
            <span className="icon icon-expand1" />
          </div>
        </WithTooltip>
      ) : (
        <div className="creative-isdevice">
          {imageType.iconClass && <div className={`device-icon icon ${imageType.iconClass}`} />}
          {imageType.displayName && (
            <WithTooltip title={imageType.displayName}>
              <div className="device-name ellipsis-text">{imageType.displayName}</div>
            </WithTooltip>
          )}
          {deviceConfig.showDimention && (
            <div className="device-width">{`${deviceConfig.width} x ${deviceConfig.height} px`}</div>
          )}
          {creativeConfig.icon && <>{creativeConfig.icon}</>}
          {creativeConfig.title && (
            <WithTooltip title={creativeConfig.title}>
              <div className="device-name ellipsis-text">
                <Trans>{creativeConfig.title}</Trans>
              </div>
            </WithTooltip>
          )}

          <div className="media-creative-actionbtn">
            {isReviewMode
              ? (isEditable || showEditIfNotEditable) && (
                  <WithTooltip>
                    <Button onClick={onToggleDrawer} link>
                      {creativeConfig.status === brandAdsCreativeStatusEnum.APPROVED ||
                      creativeConfig.status === brandAdsCreativeStatusEnum.PAUSED ||
                      creativeConfig.status === brandAdsCreativeStatusEnum.REJECTED
                        ? "View"
                        : "Review"}
                    </Button>
                  </WithTooltip>
                )
              : (isEditable || showEditIfNotEditable) && (
                  <WithTooltip title={isEditable ? "Edit" : "View"}>
                    <div
                      className={`creative-line action-icons icon ${
                        isEditable ? "icon-edit1" : "icon-eye-2"
                      }`}
                      onClick={onToggleDrawer}
                    />
                  </WithTooltip>
                )}
            {(isEditable || creativeConfig.status === "UNDER_REVIEW") && (
              <WithTooltip title="Delete">
                <div
                  className="creative-line action-icons icon icon-trash-2"
                  onClick={onClickRemove}
                />
              </WithTooltip>
            )}
            {creativeConfig.canActivePauseCreative && !isReviewMode && (
              <WithTooltip
                title={
                  creativeConfig.status === "APPROVED"
                    ? "Active, Click to Pause"
                    : "Paused, Click to Activate"
                }
              >
                <div
                  className={`creative-line icon ${
                    creativeConfig.status === "APPROVED"
                      ? "icon-toggle-on active"
                      : "icon-toggle-off paused"
                  }`}
                  onClick={
                    !isReviewMode &&
                    onToggleActivePause.bind(
                      this,
                      creativeConfig.status === "APPROVED" ? "PAUSED" : "APPROVED",
                    )
                  }
                />
              </WithTooltip>
            )}
            {creativeConfig.canActivePauseCreative && isReviewMode && (
              <WithTooltip title={creativeConfig.status === "APPROVED" ? "Active" : "Paused"}>
                <span
                  className={`icon icon-circle review-status-icon ${
                    creativeConfig.status === "APPROVED" ? "active-green" : "paused-red"
                  }`}
                  onClick={
                    !isReviewMode &&
                    onToggleActivePause.bind(
                      this,
                      creativeConfig.status === "APPROVED" ? "PAUSED" : "APPROVED",
                    )
                  }
                />
              </WithTooltip>
            )}
            {creativeConfig.canChooseCreative && (
              <WithTooltip title="Upload creatives from media library">
                <div
                  className="creative-line action-icons icon icon-upload-library"
                  onClick={onChooseCreative}
                />
              </WithTooltip>
            )}
          </div>
        </div>
      )}
      {typeof customPreviewRender === "function" ? (
        <>{customPreviewRender(creativeConfig)}</>
      ) : (
        <div className={`creative-asyncimage ${!isEditable ? "non-editable" : ""}`}>
          {hasMutipleContent(value) ? (
            <div className="media-creative-image-carousel">
              <Carousel
                indicators
                interval={null}
                wrap={false}
                prevIcon={<span className="icon icon-angle-left" />}
                nextIcon={<span className="icon icon-angle-right" />}
              >
                {Object.keys(value).map((key) => {
                  return value[key].map((v) => {
                    let id = null;
                    if (key === "images") {
                      id = "IMAGE";
                    } else if (key === "videos") {
                      id = "VIDEO";
                    } else if (key === "imagesWithText") {
                      id = "PRODUCT_LIST";
                    }
                    return <Carousel.Item>{renderPreviewContent(v, id)}</Carousel.Item>;
                  });
                })}
              </Carousel>
            </div>
          ) : (
            <>
              {value.images?.length > 0 && renderPreviewContent(value.images?.[0], "IMAGE")}
              {value.videos?.length > 0 && renderPreviewContent(value.videos?.[0], "VIDEO")}
              {value.imagesWithText?.length > 0 &&
                renderPreviewContent(value.imagesWithText?.[0], "PRODUCT_LIST")}
              {!value.images?.length && !value.videos?.length && !value.imagesWithText?.length && (
                <AsyncImage />
              )}
            </>
          )}
          {!simplePreview && (
            <WithTooltip title="Preview">
              <div
                className="preview"
                onClick={() => {
                  if (previewDatas?.length > 0) {
                    setModalStatus({
                      images: previewDatas,
                      isPlacementPreview: true,
                      isShow: true,
                    });
                  } else {
                    setModalStatus({
                      ...value,
                      isShow: true,
                    });
                  }
                }}
              >
                <span className="icon icon-eye-2" />
              </div>
            </WithTooltip>
          )}
          {creativeConfig.isCommentPresent && (
            <div className="comment" onClick={onClickComments}>
              <span className="icon icon-comments-2 dot-notificaiton" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

MediaPreview.defaultProps = {
  customPreviewRender: null,
  onPreviewClick: null,
  onClickComments: () => {},
  imageType: {},
  value: {
    images: [],
    videos: [],
  },
  deviceConfig: {},
  creativeConfig: {},
  showEditIfNotEditable: false,
  simplePreview: false,
};

const mapStateToProps = (state, ownProps) => {
  const { enableImageDownload } = state?.DomainConfig?.commonConfigs || {};
  return {
    enableImageDownload,
    ...ownProps,
  };
};

export default connect(mapStateToProps, null)(OSHOCWithUtilities(MediaPreview));
