import React from "react";
import _get from "lodash/get";
import { withTranslation } from "react-i18next";

import ImageUploader from "./imageUploader";

class MediaUploadWithLibrary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDrawerOpen: false,
    };

    const { dataKey, formValues } = this.props;
    this.currentValue = _get(formValues, dataKey);
  }

  onMediaSelectionComplete = (images = []) => {
    const { onChange, dataKey, fireIntercomEvents } = this.props;
    const img = images[dataKey] || {};
    onChange({ [dataKey]: img }, () => {
      fireIntercomEvents("ON_CHANGE_CREATIVE_SELECTION", { [dataKey]: img });
      this.toggolMediaSelectionDrawer();
    });
  };

  toggolMediaSelectionDrawer = () => {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen,
    });
  };

  onDeleteImage = (event) => {
    event.stopPropagation();
    const { onChange, dataKey, fireIntercomEvents } = this.props;
    fireIntercomEvents("ON_DELETE_CREATIVE_SELECTION", { dataKey });
    onChange({ [dataKey]: "" });
  };

  customMaxSizeToValidate = (fileType) => {
    const { maxImageSize, maxVideoSize, maxSize } = this.props;
    if (fileType === "image") {
      return maxImageSize || maxSize;
    } else if (fileType === "video") {
      return maxVideoSize || maxSize;
    }
  };

  customMinSizeToValidate = (fileType) => {
    const { minImageSize, minVideoSize, minSize } = this.props;
    if (fileType === "image") {
      return minImageSize || minSize;
    } else if (fileType === "video") {
      return minVideoSize || minSize;
    }
  };

  render() {
    const {
      imageHeight,
      imageWidth,
      maxSize,
      minSize,
      creativeType,
      fileFormats = [],
      aspectRatio,
      accept = [],
    } = this.props;

    const { isDrawerOpen } = this.state;
    const mediaLibraryProps = {
      uploadFile: {
        ...(imageHeight &&
          imageWidth && {
            dimensionValidation: {
              height: imageHeight,
              width: imageWidth,
            },
          }),
        ...(fileFormats?.length > 0 && {
          fileExtToValidates: [...fileFormats, ...(fileFormats.includes(".jpeg") ? [".jpg"] : [])],
        }),
        ...(aspectRatio && { aspectRatio }),
        ...(accept?.length > 0 && { accept }),
        mediaType: creativeType,
        maxFileSizeInMB: maxSize,
        minFileSizeInMB: minSize,
      },
    };

    return (
      <ImageUploader
        subMediaLibraryRowLayout
        clientId={this.props.clientId}
        showDrawer={isDrawerOpen}
        onChange={this.onMediaSelectionComplete}
        onDrawerClose={this.toggolMediaSelectionDrawer}
        customMaxSizeToValidate={this.customMaxSizeToValidate}
        customMinSizeToValidate={this.customMinSizeToValidate}
        useCustomCreativeFetch
        {...this.props}
        {...mediaLibraryProps}
      />
    );
  }
}

MediaUploadWithLibrary.defaultProps = {
  dataKey: null,
  title: "",
  mediaTypesToShow: [
    {
      type: "IMAGES",
      svcKey: "images",
      allowedFileTypes: "image/*",
      tabs: [
        {
          type: "CUSTOM",
          svcKey: "custom",
          displayName: `Uploaded Images`,
        },
      ],
      displayName: "Image",
    },
  ],
  validations: [
    {
      type: "nonEmpty",
      msg: "Please upload an image",
    },
  ],
  maxSize: 20,
  minSize: 0,
  fireIntercomEvents: () => {},
};

export default withTranslation()(MediaUploadWithLibrary);
