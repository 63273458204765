export const settingsTabsEnum = {
  SETTINGS_OS_AI_SETUP_CONFIGURATION: {
    key: "SETTINGS_OS_AI_SETUP_CONFIGURATION",
  },
};

export const routes = {
  LOGIN: {
    path: "/login",
  },
  FORGOT_PASSWORD: {
    path: "/forgotPassword",
  },
  CREATE_PASSWORD: {
    path: "/createPassword",
  },
  DASHBOARD: {
    path: "/dashboard",
  },
  KAM_DASHBOARD: {
    path: "/growthconsole",
  },
  SELLER_KAM_DASHBOARD: {
    path: "/growthconsole/seller",
  },
  BRAND_KAM_DASHBOARD: {
    path: "/growthconsole/brand",
  },
  ACTION_PANEL: {
    path: "/actionPanel",
  },
  ACTION_PANEL_PAGE: {
    path: "/actionPanel/:pageKey/:subPageKey?",
  },
  SMM_DASHBOARD: {
    path: "/smmDashboard",
  },
  LMS: {
    path: "/lms",
  },
  ONBOARDING: {
    path: "/onboarding",
  },
  NOT_SUBSCRIBED: {
    path: "/notSubscribed",
  },
  NOT_SIGNED: {
    path: "/notSigned",
  },
  DOWNTIME: {
    path: "/downtime",
  },
  ACCESS_DENIED: {
    path: "/accessDenied",
  },
  ROUTE_BASED_DRAWER: {
    path: "/drawer",
  },
  ROUTE_BASED_PENDING_ACTION: {
    path: "/pending_action",
  },
  BULK_FEED_UPDATE: {
    path: "/bulkFeedUpdate",
  },
  INMAR_EVENT_BUILDER: {
    path: "/inmarEventBuilder",
  },
  SETTINGS: {
    path: "/settings",
  },
  PLA: {
    path: "/pla",
  },
  PLA_SELLER_MIS: {
    path: "/pla/sellerMIS",
  },
  PLA_COHORT: {
    path: "/pla/cohort",
  },
  PLA_SKU_ANALYTICS: {
    path: "/pla/skuAnalytics",
  },
  PLA_GTM_PRIORITY: {
    path: "/pla/engagementPlan",
  },
  PLA_AD_SERVER_ANALYTICS: {
    path: "/pla/adServerAnalytics",
  },
  PLA_REAL_TIME_REPORTING: {
    path: "/pla/realTimeReporting",
  },
  DISPLAY_REAL_TIME_REPORTING: {
    path: "/displayAds/realTimeReporting",
  },
  SELLER_MIS: {
    path: "/sellerMIS",
  },
  COHORT: {
    path: "/cohort",
  },
  INSIGHTS: {
    path: "/insights",
  },
  GTM_PRIORITY: {
    path: "/engagementPlan",
  },
  LEAD_MIS: {
    path: "/leadMIS",
  },
  PAID_LEAD_MIS: {
    path: "/channelSummaryMIS",
  },
  SKU_ANALYTICS: {
    path: "/skuAnalytics",
  },
  AD_SERVER_ANALYTICS: {
    path: "/adServerAnalytics",
  },
  GOOGLE_CALLBACK: {
    path: "/googleCallback",
  },
  SKU_APPROVAL: {
    path: "/skuApproval",
  },
  BULK_ACTION: {
    path: "/bulkAction",
  },
  PARTNER_HYGIENE: {
    path: "/partnerHygiene",
  },
  ACTIVITY_LOG: {
    path: "/activityLog",
  },
  [settingsTabsEnum.SETTINGS_OS_AI_SETUP_CONFIGURATION.key]: {
    path: `/settings/${settingsTabsEnum.SETTINGS_OS_AI_SETUP_CONFIGURATION.key}`,
  },
  CHANGE_HISTORY: {
    path: "/changeHistory",
  },
  BRAND_ADS: {
    path: "/brandAds",
  },
  DISPLAY_ADS: {
    path: "/displayAds",
  },
  DISPLAY_ADS_CAMPAIGN_REVIEW: {
    path: "/displayAds/campaignReview",
  },
  DISPLAY_ADS_MERCHANT_INSIGHTS: {
    path: "/displayAds/merchantInsights",
  },
  DISPLAY_ADS_INVENTORY_INSIGHTS: {
    path: "/displayAds/inventoryInsights",
  },
  DISPLAY_ADS_INVENTORY_CALENDAR_INSIGHTS: {
    path: "/displayAds/inventoryCalendarInsights",
  },
  PERFORMANCE: {
    path: "/performance",
  },
  PULSE: {
    path: "/pulse",
  },
  SUPPORT: {
    path: "/support",
  },
  SUPPORT_TICKET_DETAIL_VIEW: {
    path: "/support/:pageKey?",
  },
  GOOGLE_SSO_CALLBACK: {
    path: "/googleSSOCallback",
  },
  USER_NOT_FOUND: {
    path: "/userNotFound",
  },
};
