import _uniq from "lodash/uniq";
import _keyBy from "lodash/keyBy";

import { customMergeOS } from "@onlinesales-ai/util-methods-v2";

import Types from "./types";

const initialState = {
  campaign: {
    data: {},
    sortedIds: [],
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.TTYA_SET_CAMPAIGN_DATA:
      {
        const data = _keyBy(action?.campaign || [], "id");
        const sortedIds = [];
        (action?.campaign || []).forEach((item) => {
          if (item.id) {
            sortedIds.push(item.id);
          }
        });
        state = {
          ...state,
          campaign: {
            ...state.campaign,
            ...(action.restData || {}),
            ...(action.isSetSortedIds && { sortedIds }),
            data: customMergeOS({}, state?.campaign?.data, data),
            payload: action.payload,
          },
        };
      }
      break;
    case Types.TTYA_UPDATE_CAMPAIGN_DATA:
      {
        const sortedIds = state?.campaign?.sortedIds || [];
        // Adding new Created campaigns ids to the top of the list
        if (!sortedIds?.includes(action.id) && action.updateSortedIds) {
          sortedIds.unshift(action.id);
        }
        state = {
          ...state,
          campaign: {
            ...state.campaign,
            data: {
              ...state.campaign.data,
              [action.id]: {
                ...customMergeOS({}, state?.campaign?.data?.[action.id] || {}, action?.data),
              },
            },
            sortedIds,
          },
        };
      }
      break;
    case Types.TTYA_SET_CAMPAIGN_AUDIENCE:
      {
        state = {
          ...state,
          campaign: {
            ...state.campaign,
            data: {
              ...state.campaign.data,
              [action.campaignId]: {
                ...state.campaign?.data?.[action.campaignId],
                audience: {
                  ...state?.campaign?.data?.[action.campaignId]?.audience,
                  ...action?.audience,
                },
              },
            },
          },
        };
      }
      break;
    case Types.TTYA_SET_AND_REPLACE_CAMPAIGN_AUDIENCE:
      {
        state = {
          ...state,
          campaign: {
            ...state.campaign,
            data: {
              ...state.campaign.data,
              [action.campaignId]: {
                ...state.campaign.data[action.campaignId],
                audience: {
                  ...action.audience,
                },
              },
            },
          },
        };
      }
      break;
    case Types.TTYA_UPDATE_CAMPAIGN_AUDIENCE:
      {
        state = {
          ...state,
          campaign: {
            ...state.campaign,
            data: {
              ...state.campaign.data,
              [action.campaignId]: {
                ...state.campaign.data[action.campaignId],
                audience: {
                  ...state.campaign.data[action.campaignId].audience,
                  [action.audience.id]: {
                    ...state.campaign.data[action.campaignId].audience[action.audience.id],
                    ...action.audience,
                  },
                },
              },
            },
          },
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  TTYA: reducer,
};
