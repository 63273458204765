import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";

import _get from "lodash/get";
import _find from "lodash/find";
import { withTranslation, Trans, useTranslation } from "react-i18next";

import { MediaLibraryDrawer, MediaUpload } from "@onlinesales-ai/media-library-v2";
import PopoverTooltip from "@onlinesales-ai/popover-tooltip-v2";
import FormWrapper from "@onlinesales-ai/form-components-v2/src/FormWrapper";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import { creativeApprovalMark, detectMediaTypeFromURL, formatWithTZ, getUrlParams, useMemoCompare } from "@onlinesales-ai/util-methods-v2";
import { Text } from "@onlinesales-ai/label-v2";
import { DEFAULT_DATE_TIME_FORMAT_TZ } from "@onlinesales-ai/constants-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";

import MediaPreview from "./mediaPreview";

import "./index.less";

const defaultMediaProps = {
  mediaToShow: ["CREATIVES"],
  mediaType: "IMAGE",
  filtersToShow: ["dimensions", "type", "size"],
  filterListConfig: {
    dimensions: {
      key: "dimensions",
      apiFetchKey: "UNIQUE_DIMENSIONS",
      displayName: "Dimensions",
      type: "RADIO_BUTTON",
      isDataFetchInProgress: false,
      emptyMsg: "No dimensions found",
      layout: "two-column",
      componentMetaData: {
        items: [],
      },
    },
    type: {
      key: "type",
      displayName: "Type",
      type: "RADIO_BUTTON",
      componentMetaData: {
        items: [
          {
            label: "Image",
            value: "IMAGE",
          },
          {
            label: "Video",
            value: "VIDEO",
          },
        ],
      },
    },
    size: {
      key: "size",
      displayName: "Media Size",
      type: "RANGE_FILTER",
      minInputProps: {
        suffix: "MB",
      },
      maxInputProps: {
        suffix: "MB",
      },
      componentMetaData: {},
    },
  },
};

const DeviceTypeToIconMapping = {
  WEB: {
    displayName: "Web",
    iconClass: "icon-devices_web",
  },
  MOBILE: {
    displayName: "Mobile",
    iconClass: "icon-devices_mob",
  },
  TABLET: {
    displayName: "Tablet",
    iconClass: "icon-devices_tab",
  },
};

const ImageUploader = ({
  dataKey,
  formValues,
  onChange,
  onError,
  validations = [],
  title,
  isEditable,
  disabled,
  clientId,
  uploadTags,
  uploadFile,
  formGroupClassName,
  guidText,
  guidElement,
  apiError,
  warning,
  showErrors,
  formErrors,
  isMobile,
  userInfo,
  creativeTag,
  mediaLibraryProps: mediaLibrary,
  nonEditableTooltipPopoverMsg,
  fireIntercomEvents,
  showConfirmationModal,
  resetConfirmationModal,
  deviceConfig,
  creativeType,
  fileFormats,
  isReviewMode,
  enableTemplateSelection,
  customMaxSizeToValidate,
  customMinSizeToValidate,
  goalDetails,
  adCreativeId,
  isShowCreativeApprovalStatus,
  creativeLibraryFilterConfigs,
  creativeLibraryFilterToShow,
  errorClassName,
  formGroupInnerClassName,
  labelColumns,
}) => {
  const params = getUrlParams();
  const { goalId } = params;
  const newImageRef = useRef();
  const { t } = useTranslation();
  const browseBtnRef = useRef();
  const popoverMenu = useRef({
    toogle: () => {},
  });
  const { showToastMessage } = useContext(GlobalContext);
  const [state, setState] = useState({
    isOpen: false,
  });

  const memoisedValue = useMemoCompare(_get(formValues, dataKey));

  const creativeMapping = useMemo(() => {
    return _get(
      goalDetails?.[goalId]?.data?.creatives?.find((k) => k?.adCreativeId == adCreativeId)
        ?.elementMappings,
      dataKey,
    );
  }, [dataKey, goalDetails, adCreativeId, goalId]);

  const validate = (value) => {
    let errorMsg = null;

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "nonEmpty":
          {
            if (!value) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        case "urlExt":
          {
            if (value && validations[i].extList) {
              const ext = value.split(/[#?]/)?.[0]?.split(".")?.pop()?.trim();
              if (ext && !validations[i].extList?.includes(ext)) {
                errorMsg = validations[i].msg;
              }
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        fireIntercomEvents("ON_VALIDATE_CREATIVE_SELECTION", { [dataKey]: errorMsg });
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }
    onError({ [dataKey]: errorMsg });
  };
  useEffect(() => {
    if (creativeType === "MEDIA") {
      const newValue = _get(formValues, dataKey)?.value;
      validate(newValue);
    } else {
      const newValue = _get(formValues, dataKey);
      validate(newValue);
    }
  }, [memoisedValue]);

  const getValue = () => {
    if (creativeType === "MEDIA") {
      return _get(formValues, dataKey)?.value;
    }
    return _get(formValues, dataKey);
  };

  const onToggleDrawer = () => {
    if (!disabled && isEditable) {
      fireIntercomEvents("ON_TOGGEL_CREATIVE_SELECTION_DRAWER", { isOpen: !state.isOpen });
      setState({
        isOpen: !state.isOpen,
      });
    }
  };

  const onMediaSelect = (value) => {
    const { height, width } = uploadFile?.dimensionValidation || {};
    const extraObjectToSend = {
      config: value?.[0]?.mediaObj,
      key: dataKey,
      action: "ADD",
    };
    fireIntercomEvents("ON_SELECT_CREATIVE_SELECTION", { [dataKey]: value?.[0]?.url });
    if (creativeType === "MEDIA") {
      onChange({
        [dataKey]: { value: value?.[0]?.url, height, width, type: value?.[0]?.type },
      }, extraObjectToSend);
    } else {
      onChange({
        [dataKey]: value?.[0]?.url,
      }, extraObjectToSend);
    }
    onToggleDrawer();
  };

  const onUploadMedia = (url, config, fileType) => {
    const { height, width } = uploadFile?.dimensionValidation || {};
    const extraObjectToSend = { config, key: dataKey, action: "ADD" };
    if (creativeType === "MEDIA") {
      fireIntercomEvents("ON_UPLOAD_CREATIVE_SELECTION", {
        [dataKey]: { value: url, height, width, type: config?.type },
      });
      onChange({
        [dataKey]: { value: url, height, width, type: config?.type },
      }, extraObjectToSend);
    } else {
      fireIntercomEvents("ON_UPLOAD_CREATIVE_SELECTION", { [dataKey]: url });
      onChange({
        [dataKey]: url,
      }, extraObjectToSend);
    }
  };

  const onClickRemove = (event) => {
    event.stopPropagation();
    if (isEditable) {
      showConfirmationModal({
        isShow: true,
        title: t("Are you sure want to remove this creative?"),
        actionBtnText: "Yes",
        rightBtnText: "No",
        actionBtnCallback: () => {
          fireIntercomEvents("CLICK_REMOVE_CREATIVE_CONFIRM");
          onChange({
            [dataKey]: null,
          }, { action: "REMOVE" });
          resetConfirmationModal();
        },
        rightBtnCallback: () => {
          fireIntercomEvents("CLICK_REMOVE_CREATIVE_CANCEL");
          resetConfirmationModal();
        },
      });
    }
  };

  const onUploadError = (errorMsg) => {
    fireIntercomEvents("ON_UPLOAD_ERROR_CREATIVE_SELECTION", { errorMsg });
    showToastMessage({
      type: "ERROR",
      messageToDisplay: errorMsg,
      actionButtonLabel: null,
      toastDuration: 5000,
    });
  };

  const onMenuSelect = (value) => {
    fireIntercomEvents("ON_SELECT_POPOVER_MENU_CREATIVE_SELECTION", { value });
    if (value === "ChooseLibrary") {
      onToggleDrawer();
    } else if (value === "MediaUpload" && browseBtnRef) {
      browseBtnRef.current.click();
      popoverMenu.current.toogle(false);
    }
  };

  const customPreviewRender = () => {
    return (
      <div className="media-creative-form-btn">
        <div
          className="media-creative-actionbtn"
          onClick={() => {
            if (isEditable) {
              onMenuSelect("MediaUpload");
            }
          }}
        >
          <AsyncImage imgSrc="https://res.cloudinary.com/onlinesales/image/upload/w_70,f_auto,q_auto/product/file-upload.svg" />
          {/* <span ref={newImageRef} className="upload-img icon icon-plus creative-line" /> */}
          <div className="upload-text">
            <Trans>Upload {creativeType === "MEDIA" ? "Image/Video" : creativeType === "IMAGE" ? "Image" : "Video"}</Trans>
          </div>
        </div>
      </div>
    );
  };

  const { height, width } = uploadFile?.dimensionValidation || {};

  const mediaLibraryProps = {
    ...mediaLibrary,
    height,
    width,
    mediaType: creativeType,
    filterListConfig: creativeLibraryFilterConfigs || defaultMediaProps?.filterListConfig,
    filtersToShow: creativeLibraryFilterToShow || defaultMediaProps?.filtersToShow,
  };

  const { isOpen } = state;

  const renderCreativeBtnDom = () => {
    const value = getValue();
    const { height, width } = uploadFile?.dimensionValidation || {};
    const imageType = {
      iconClass: DeviceTypeToIconMapping[deviceConfig?.type]?.iconClass,
      displayName: deviceConfig?.displayName,
    };
    const typeToSet = detectMediaTypeFromURL({ url: value, videoKey: "videos", imageKey: "images" });

    const data = {
      ...(creativeType === "VIDEO" && { videos: [value] }),
      ...(creativeType === "IMAGE" && { images: [value] }),
      ...(creativeType === "MEDIA" && { [typeToSet]: [value] }),
    };

    return (
      <div
        className={`media-creative-action-block position-relative width-fit ${value ? "is-creative-image" : "is-creative-btn"}${
          disabled ? "disabled" : ""
        } ${!isEditable ? "not-editable" : ""}`}
      >
        {value ? (
          <MediaPreview
            dataKey={dataKey}
            deviceConfig={{
              showName: true,
              height,
              width,
              showDimention: !!width && !!height,
              showCreativeStatus: isShowCreativeApprovalStatus,
            }}
            isEditable={isEditable}
            imageType={imageType}
            value={data}
            onToggleDrawer={onToggleDrawer}
            onClickRemove={onClickRemove}
            isReviewMode={isReviewMode}
          />
        ) : (
          <>
            <MediaUpload
              isValidateSize
              isValidateDimension
              onUploadError={onUploadError}
              inputRef={browseBtnRef}
              clientId={clientId}
              containerClass="creative-mediaupload"
              onUpload={onUploadMedia}
              extraPostPayload={{
                height,
                width,
              }}
              cloudinaryTags={[...(uploadTags || []), `creative_user_upload`]}
              disabled={!isEditable}
              customMaxSizeToValidate={customMaxSizeToValidate}
              customMinSizeToValidate={customMinSizeToValidate}
              {...mediaLibraryProps}
              {...uploadFile}
            />
            <MediaPreview
              customPreviewRender={customPreviewRender}
              onChooseCreative={() => {
                onMenuSelect("ChooseLibrary");
              }}
              creativeConfig={{
                canChooseCreative: true,
              }}
              deviceConfig={{
                showDimention: !!width && !!height,
                width,
                height,
              }}
              imageType={imageType}
            />
          </>
        )}
        {isShowCreativeApprovalStatus && isReviewMode && creativeMapping?.approvalStatus === "APPROVED" && <Text className="pt-1" type="success" size="small" italic>{creativeMapping?.approvedEpochMs && `Creative last approved: ${formatWithTZ(creativeMapping?.approvedEpochMs, DEFAULT_DATE_TIME_FORMAT_TZ)}`}</Text>}
        <div className="error-msg">
          {showErrors && formErrors[dataKey] && <span>{formErrors[dataKey]}</span>}
        </div>
      </div>
    );
  };

  const customStatusDom = ({ status, metadata }) => {
    return creativeApprovalMark({
      status: metadata?.approvalStatus,
      approvedEpochMs: metadata?.approvedEpochMs,
    });
  };

  return (
    <FormWrapper
      labelColumns={labelColumns}
      formGroupClassName={`${formGroupClassName} ${
        showErrors && formErrors[dataKey] ? "creative-error" : ""
      }`}
      apiError={apiError}
      guidElement={guidElement}
      guidText={guidText}
      warning={warning}
      isEditable={isEditable}
      title={title}
      errorClassName={errorClassName}
      formGroupInnerClassName={formGroupInnerClassName}
    >
      <MediaLibraryDrawer
        isOpen={isOpen}
        onMediaSelect={onMediaSelect}
        onClose={onToggleDrawer}
        isMobile={isMobile}
        clientId={clientId}
        userInfo={userInfo}
        creativeTag={creativeTag}
        uploadFile={uploadFile}
        isSelectable
        extraPostPayload={{
          height,
          width,
        }}
        uploadTags={uploadTags || []}
        useCustomCreativeFetch
        enableTemplateSelection={enableTemplateSelection}
        customStatusDom={customStatusDom}
        showCreativeStatus={isShowCreativeApprovalStatus}
        {...{ ...defaultMediaProps, ...mediaLibraryProps }}
        containerClass="media-library-creative"
      />
      {isEditable ? (
        renderCreativeBtnDom()
      ) : (
        <PopoverTooltip underline={false} popoverClass="media-not-editable-tooltip" placement="bottom" popoverContent={isReviewMode ? "" : nonEditableTooltipPopoverMsg} containerClass="creative-tooltip-popover">
          {renderCreativeBtnDom()}
        </PopoverTooltip>
      )}
    </FormWrapper>
  );
};

ImageUploader.defaultProps = {
  dataKey: null,
  title: "",
  labelColumns: 3,
  mediaLibraryProps: {},
  validations: [
    {
      type: "nonEmpty",
      msg: "Please upload creative",
    },
  ],
  nonEditableTooltipPopoverMsg: "Media is not editable",
};

const mapStateToProps = (state) => {
  const { goalDetails = {} } = state.BrandAds;
  const { filtersToShow, filterListConfig } =
  state.DomainConfig?.commonConfigs?.creativeConfig || {};
  return {
    goalDetails,
    creativeLibraryFilterToShow: filtersToShow,
    creativeLibraryFilterConfigs: filterListConfig,
  };
};

export default connect(mapStateToProps)(withTranslation()(OSHOCWithUtilities(ImageUploader)));
